import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})

export class DirectoryComponent implements OnInit {
  directoryVerifyform: FormGroup;
  dirUrl: string = "";
  showResponse: boolean = false;

    constructor( private titleService: Title, private directoryVerifyBuilder: FormBuilder ) {
        this.titleService.setTitle('St George Orthodox Syrian Cathedral - Directory Verification');
    }

    ngOnInit() {
      this.createForm();
    }

    createForm() {
      this.directoryVerifyform = this.directoryVerifyBuilder.group({
        regnum: ['', [Validators.required, Validators.minLength(1)]],
      });
    }

    login() {
      if (this.directoryVerifyform.invalid) {
        this.showResponse = false;
        return;
      } else {
        this.showResponse = true;
        this.dirUrl= `https://directorychurch.s3.ap-south-1.amazonaws.com/${this.directoryVerifyform.value.regnum}.jpg`
      }
    }

}
