import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { ActivitiesService } from '../../../services/activities.service'
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-prayer-request',
  templateUrl: './prayer-request.component.html',
  styleUrls: ['./prayer-request.component.scss']
})
export class PrayerRequestComponent implements OnInit {
  prayerRequestform: FormGroup;
  spinnerTrue: boolean = false;
  showAlert: boolean = false;
  alertMessage: string = "";
  alertStatus: string = "";
  constructor( private titleService: Title, private prayerRequestBuiilder: FormBuilder, private activities: ActivitiesService) {
    this.titleService.setTitle('St George Orthodox Syrian Cathedral - Prayer Request');
    this.createForm();
   }

  ngOnInit(): void {
  }

  createForm() {
    this.prayerRequestform = this.prayerRequestBuiilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(2)]],
      sick: [],
      blessings: [],
      departed: []
    });
  }

  submitRequestPrayer() {
    if (this.prayerRequestform.invalid) {
      return;
    }  
    this.spinnerTrue = true;
    this.activities.postPrayerRequest(this.prayerRequestform.value)
      .pipe(first())
      .subscribe(
        data => {
          this.spinnerTrue = false;
          this.showAlert = true;
          this.prayerRequestform.reset();
          this.alertStatus = "success";
          this.alertMessage = "Successfully submitted names"
          setTimeout(() => {
            this.showAlert = false;
            this.alertMessage = "";
          }, 5000);
        },
        error => {
          this.spinnerTrue = false;
          this.showAlert = true;
          this.alertStatus = "error"
          this.alertMessage = "Failed to submitted names. Please try after sometime"
          setTimeout(() => {
            this.showAlert = false;
            this.alertMessage = "";
          }, 5000);
        });
    
  }
}
