<section class="page-title-banner-custom saas-work-process-custom variant4">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Welcome</h2>
                <p>List of items on Dashboard can be accessed by clicking on it</p>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>

<section class="saas-work-process-custom variant4 ptb-100">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-work-process" routerLink="/prayer-request-admin">
                    <div class="icon">
                        <i class="icofont-book"></i>
                    </div>
                    <h3>Prayer Requests</h3>
                    <p>Names to be remembered during Holy Qurbana</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-responsive"></i>
                    </div>
                    <h3>Messages</h3>
                    <p>Messages received</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-ui-settings"></i>
                    </div>
                    <h3>Online Payment Details</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-work-process">
                    <div class="icon">
                        <i class="icofont-thumbs-up"></i>
                    </div>
                    <h3>TBD</h3>
                    <p>Morbi pharetra sapien ut mattis.</p>
                </div>
            </div>
        </div>
    </div>
</section>