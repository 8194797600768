<!-- Start SaaS Main Banner -->
<div class="main-banner saas-work-process-variant1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/church-img/church-img-2.jpg" class="wow fadeInDown church-img" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/church-img/church-img-2.jpg" class="wow zoomIn church-img" data-wow-delay="0.6s" alt="main-image.png" style="top:0;left:0">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>St George's Orthodox Syrian Cathedral</h1>
                            <p>Our Cathedral is under the Trivandram Diocese of the Malankara Orthodox Syrian Church founded by St. Thomas, Apostle of Jesus Christ, who came to India (A.D 52). </p>
                            <!-- <a routerLink="/" class="btn btn-primary">Get Started</a> -->
                            <div class="col-lg-12 col-md-12">
                               <a [href]="videoUrl" target="_blank" class="video-btn btn btn-primary"> Watch Holy Qurbana Live<i class="icofont-play-alt-3"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape1"><img src="assets/church-img/logo-mosc.png" alt="img"></div> -->
    <!-- <div class="shape3"><img src="assets/img/shape2.png" alt="img"></div> -->
    <!-- <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div> -->
    <!-- <div class="shape2"><img src="assets/church-img/george.jpg" alt="img"></div> -->
    <div class="shape4 rotateme"><img src="assets/img/shape4.png" alt="img"></div>
</div>
<!-- End SaaS Main Banner -->
<!-- Start SAAS Tools Area -->
<section class="saas-tools ptb-100 bg-gray saas-work-process-custom variant2">
    <div class="container">
        <div class="section-title">
            <h2>Church Events</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="tab">
                    <ul class="tabs">
                        <li><a href="#">
                            <i class="icofont-copy-invert"></i>
                            <br>
                            Church Ministries
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-wall-clock"></i>
                            <br>
                            Timings
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-inbox"></i>
                            <br>
                            Upcoming Events
                        </a></li>

                        <li><a href="#">
                            <i class="icofont-ui-calendar"></i>
                            <br>
                            Notices
                        </a></li>

                    </ul>

                    <div class="tab_content">

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/church-img/bible-image.jpg" alt="img">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Church Ministries</h3>
                                        <p>Church nurtures and supports several spiritual institutions which functions within the church</p>
                                        <ul>
                                            <li><i class="icofont-star"></i> Prayer Groups</li>
                                            <li><i class="icofont-star"></i> Orthodox Sunday School Association of the East(OSSAE)</li>
                                            <li><i class="icofont-star"></i> Orthodox Christian Youth Movement (OCYM)</li>
                                            <li><i class="icofont-star"></i> Martha Mariam Samajam (MMS)</li>
                                            <li><i class="icofont-star"></i> Balika-Bala Samajam</li>
                                            <li><i class="icofont-star"></i> Senior Citizen's Forum</li>
                                            <li><i class="icofont-star"></i> Suvishesha Sangam</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                
                                <div class="col-lg-6 col-md-6">  
                                    <div class="tabs_item_content mt-0">
                                        <h3>Holy Qurbana <a [href]="videoUrl" target="_blank" class="video-btn btn btn-primary"> Live Streaming<i class="icofont-play-alt-3"></i></a></h3>
                                        <h4><span class="badge rounded-pill bg-info text-white">Sunday</span></h4>
                                        <ul>
                                            <li><b>First Service</b></li>
                                            <li><i class="icofont-clock-time col-sm-3"></i> Morning Prayer: 5.45 am
                                            <i class="icofont-clock-time col-sm-3"></i> Holy Qurbana: 6.15 am</li>
                                            <li><b>Second Service</b></li>
                                            <li><i class="icofont-clock-time col-sm-3"></i> Morning Prayer: 7.45 am
                                            <i class="icofont-clock-time col-sm-3"></i>  Holy Qurbana: 8.15 am</li>
                                        </ul>
                                        <!-- <h4 class="mt-3"><span class="badge rounded-pill bg-info text-white">Wednesday</span></h4>
                                        <ul>
                                            <li><i class="icofont-clock-time col-sm-3"></i> Morning Prayer: 6.15 am
                                            <i class="icofont-clock-time col-sm-3"></i> Holy Qurbana: 6.45 am</li>
                                        </ul> -->
                                        <h4 class="mt-3"><span class="badge rounded-pill bg-info text-white">Saturday</span></h4>
                                        <ul>
                                            <li><i class="icofont-clock-time col-sm-3"></i> Morning Prayer: 6.15 am
                                            <i class="icofont-clock-time col-sm-3"></i> Holy Qurbana: 6.45 am</li>
                                        </ul>
                                    </div>
                                    <div class="tabs_item_content mt-3">
                                        <h3>Evening Prayers </h3>
                                        <h4><span class="badge rounded-pill bg-info text-white"><i class="icofont-clock-time col-sm-3"></i> Everyday: 6.00 pm</span></h4>   
                                    </div>
                                    <!-- <div class="tabs_item_content mt-3">
                                        <i>* Holy Qurbana - Second Service is currently suspended</i>
                                    </div> -->
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/church-img/Church-image.jpg" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                

                                <div class="col-lg-12  col-md-12">
                                    <div class="tabs_item_content mb-0">
                                        <h3>Upcoming Events</h3>
                                        <p>There are no upcoming events</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-12 col-md-12">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Notices</h3>
                                        <p>There are no Notices</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="tabs_item">
                            <div class="row h-100 justify-content-center align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_content mt-0">
                                        <h3>Reporting</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                                        <ul>
                                            <li><i class="icofont-ui-check"></i> Creative Design</li>
                                            <li><i class="icofont-ui-check"></i> Retina Ready</li>
                                            <li><i class="icofont-ui-check"></i> Modern Design</li>
                                            <li><i class="icofont-ui-check"></i> Digital Marketing & Branding</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="tabs_item_img">
                                        <img src="assets/img/features-img1.png" alt="img">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shape1"><img src="assets/church-img/logo-mosc.png" alt="img"></div>
    <div class="shape2"><img src="assets/church-img/Icon-st-mary.jpeg" alt="img"></div> -->
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
</section>
<!-- End SAAS Tools Area -->


<app-partner></app-partner>