<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-custom variant3 d-print-none">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Names to be remembered in Holy Qurbana</h2>
                <p>Table contains list of names entered to be remembered in Holy Qurbana</p>
                <div class="bar"></div>            
            </div>
        </div>
    </div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->
<div class="row">
    <div class="col-lg-12 d-none d-print-block">
        <h2>Names to be remembered in Holy Qurbana</h2>
        <p>{{today}}</p>
    </div>
</div>
<!-- Start Contact Area -->

<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="card shadow-lg-custom p-3 mb-5 bg-white rounded">
            <div class="card-body"> 
                <p *ngIf="!renderTable">No Names submitted through website</p> 
            <div class="card-body" style="display: flex; justify-content: space-between ;">              
             <button class="btn btn-primary d-print-none mb-2" (click)="windowPrint()" *ngIf="renderTable">Print</button>
             <button class="btn btn-primary d-print-none mb-2 ml-2" (click)="routingAction()" *ngIf="renderTable">Dashboard</button>
            </div>
          <div class="row h-100 justify-content-center">
            <ng-container *ngIf="renderTable">
              <div class="col-lg-12">
                <table class="table table-striped table-bordered nowrap table-hover scrollX table-sm" >
                  <thead>
                    <tr>
                      <th>Blessings</th>
                      <th>Sick</th>
                      <th>Departed</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let group of processedArray">
                         <td>{{group.blessings}} </td>
                         <td>{{group.sick}} </td>
                         <td>{{group.departed}} </td>

                     </tr>  
                  </tbody>
                  
                </table>
              </div>
            </ng-container>
        
            </div>
    </div>
</div>
</div>
</section>
<!-- End Contact Area -->