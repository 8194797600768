<section class="page-title-banner-custom saas-work-process-custom variant3">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Live Streaming</h2>
                <p>Watch Christmas Eve | Nuharo 21</p>
                <div class="bar"></div>            
            </div>
        </div>
    </div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>

<section class="overview-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Christmas Eve | Nuharo 21</h2>
            <div class="bar"></div>
        </div>
      
        <div class="video-container">
             <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe> 
        </div>
       
    </div>

    <div class="shape7"><img src="assets/img/shape7.png" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="bg-gray shape-1"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    <div class="shape10"><img src="assets/img/shape10.svg" alt="shape"></div>
    <div class="shape11 rotateme"><img src="assets/img/shape11.svg" alt="shape"></div>
</section>