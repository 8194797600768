import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {

  constructor(private titleService: Title) {
    this.titleService.setTitle('St George Orthodox Syrian Cathedral - Welcome Page');
   }

  ngOnInit(): void {
  }

}
