import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivitiesService } from '../../../services/activities.service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prayer-request-admin',
  templateUrl: './prayer-request-admin.component.html',
  styleUrls: ['./prayer-request-admin.component.scss']
})
export class PrayerRequestAdminComponent implements OnInit {
  dtOptions: any = {};
  prayerRequestValues = [];
  processedArray = [];
  renderTable: boolean;
  today = new Date();


  constructor( private titleService: Title, private activities: ActivitiesService, private router: Router) {
    this.titleService.setTitle('St George Orthodox Syrian Cathedral - Prayer Request Admin View');
   }
   
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
   
      dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ]
    };
    this.getPrayerRequests();
  }

  getPrayerRequests() {
    this.renderTable= false;
    this.activities.getPrayerRequest()
      .pipe(first())
      .subscribe(
        data => {
          console.log("dataa", data)
          this.prayerRequestValues = data.reduce((accumulator, item) => {
            Object.keys(item).forEach(key => {
              if(item[key]){
                accumulator[key] = accumulator[key] ? (accumulator[key]) + ',' + item[key] : item[key] ;
                accumulator[key] = accumulator[key].split(",")
              }  
              
            });
            return accumulator;
          }, {});
          let totalArrayLength = 0;
          if ( this.prayerRequestValues['sick'] && this.prayerRequestValues['sick'].length > totalArrayLength){
            totalArrayLength = this.prayerRequestValues['sick'].length
          }
          if (this.prayerRequestValues['blessings'] && this.prayerRequestValues['blessings'].length > totalArrayLength){
            totalArrayLength = this.prayerRequestValues['blessings'].length
          }
          if (this.prayerRequestValues['blessings'] && this.prayerRequestValues['departed'].length > totalArrayLength){
            totalArrayLength = this.prayerRequestValues['departed'].length
          }
          this.processedArray = [];
          for (let i=0; i < totalArrayLength; i++){
            let valueObject = {blessings:this.prayerRequestValues["blessings"][i] || "", departed:this.prayerRequestValues["departed"][i] || "", sick:this.prayerRequestValues["sick"][i] || ""}
            this.processedArray.push(valueObject);
          }
          this.renderTable= true;
          console.log("reducedArray", this.prayerRequestValues)
          console.log("processedArray", this.processedArray)


        },
        error => {
          
        });
    
  }

  windowPrint(){
    window.print();
  }

  routingAction(){
    this.router.navigate(['/login/signed-in']);
  }


}
