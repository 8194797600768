<section class="page-title-banner-custom saas-work-process-custom variant3">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Evangelion Chapters</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>

<section class="team-area ptb-100">
    <table id="doccontent" class="lectbl" cellspacing="0">
        <thead><tr><th scope="row"><h1 class="PageTit">Scripture Readings 2023</h1></th></tr></thead>
        <tbody><tr><td align="center" scope="row" style="position: fixed; width: 34em; right: calc(0vw - 16em); top: 50vh; background: #ffcc33; transform: rotate(-90deg); border-radius: 5px;"></td></tr>
        <tr><td>
        <div class="DayBox">
        <h3 class="SubTit">January-1: New Year's Day, Circumcision of our Lord, Memory of St. Basil and St. Gregory.</h3>
        <div style="page-break-inside: avoid" id="013" name="013">
        <div class="MsgTit" id="013Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2013:6%20-%209');">
        St. Luke 13: 6 - 9</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%209:4%20-7');">
        St. John 9: 4 -7</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2012:1-%209');">
        Genesis 12 : 1- 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2010:12-11:1');">
        Deuteronomy 10: 12-11: 1</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2018:21-24');">
        Ezekiel 18: 21-24</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%203:13%20-%2018');">
        I John 3: 13 - 18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%202:28-3:8');">
        Romans 2: 28-3: 8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2015:5%20-19');">
        St. John 15: 5 -19</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-6: Baptism of our Lord Jesus Christ</h3>
        <div style="page-break-inside: avoid" id="015" name="015">
        <div class="MsgTit" id="015Txt">Description<div class="MsgTxt">
        
        This festival is called Danaha in Syriac meaning 'Dawn'. Also called
        
        Epiphany or Theophany.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%201:1-11');">
        St. Mark 1: 1-11</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%203:1%20-17');">
        St. Matthew 3 : 1 -17</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2024:10%20-20');">
        Genesis 24: 10 -20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%203:19-25');">
        II Kings 3 : 19-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2047:1-9');">
        Ezekiel 47: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2012:1-6');">
        Isaiah 12: 1-6</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2019:1%20-7');">
        Acts 19 : 1 -7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Titus%202:11-%203:9');">
        Titus 2: 11- 3: 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%203:15-22');">
        St. Luke 3: 15-22</a></div>
        </div>Blessing of the Water<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%208:35-40');">
        Acts 8: 35-40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%2010:15-25');">
        Hebrews 10: 15-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%204:1-42');">
        St. John 4: 1-42</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-7: Beheading of St. John the Baptist</h3>
        <div style="page-break-inside: avoid" id="016" name="016">
        <div class="MsgTit" id="016Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%203:1-14,19-20');">
        St. Luke 3: 1-14, 19-20</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%206:14-29');">
        St. Mark 6: 14-29</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%204:1%20-%2015');">
        Genesis 4: 1 - 15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2037:11-21');">
        Jeremiah 37: 11-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2040:3-8,41:8-13,');">
        Isaiah 40: 3-8, 41: 8-13,</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:12%20-%2017');">
        I Peter 3: 12 - 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%208:35-%2039');">
        Romans 8 : 35- 39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2014:1-12');">
        St. Matthew 14: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-8: Feast of St. Stephen</h3>
        <div style="page-break-inside: avoid" id="017" name="017">
        <div class="MsgTit" id="017Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2012:1-12');">
        St. Mark 12: 1-12</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%208:34-59');">
        St. John 8: 34-59</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%204:1-%2015');">
        Genesis 4: 1- 15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2037:8-20');">
        Isaiah 37: 8-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Barazeera%2050:1%20-2');">
        Barazeera 50 :1 -2</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%207:51%20-%208:2');">
        Acts 7: 51 - 8: 2</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%208:35-%2039');">
        Romans 8: 35- 39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2023:34-39');">
        St. Matthew 23: 34-39</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-15: Second Sunday after Baptism of our Lord</h3>
        <div style="page-break-inside: avoid" id="019" name="019">
        <div class="MsgTit" id="019Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%201:26-34');">
        St. John 1: 26-34</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%201:35-42');">
        St. John 1: 35-42</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2022:26-33');">
        Leviticus 22: 26-33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Proverbs%209:1%20-%209');">
        Proverbs 9 : 1 - 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2051:1-8');">
        Isaiah 51: 1-8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:7-15');">
        I Peter 3: 7-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%201:1-2:4');">
        Hebrews 1: 1-2: 4</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%201:43%20-%2051');">
        St. John 1: 43 - 51</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-22: Third Sunday after Baptism of our Lord</h3>
        <div style="page-break-inside: avoid" id="020" name="020">
        <div class="MsgTit" id="020Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%203:22-4:3');">
        St. John 3: 22-4: 3</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%205:30%20-%2047');">
        St. John 5: 30 - 47</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%202:19-22');">
        II Kings 2: 19-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joel%202:21-32');">
        Joel 2: 21-32</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2031:1-12');">
        Jeremiah 31: 1-12</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2017:16%20-%2034');">
        Acts 17: 16 - 34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%203:16-4:5');">
        I Corinthians 3: 16-4: 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%203:1-12');">
        St. John 3: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-29: Fourth Sunday after Baptism (Sunday for Workers)</h3>
        <div style="page-break-inside: avoid" id="021" name="021">
        <div class="MsgTit" id="021Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%207:18%20-%2035');">
        St. Luke 7: 18 - 35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2012:28%20-%2037');">
        St. Mark 12: 28 - 37</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%202:4-%2017');">
        Genesis 2: 4- 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2018:9-16');">
        Deuteronomy 18 : 9-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2040:27%20-31');">
        Isaiah 40: 27 -31</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2018:1-%204,20:33-35');">
        Acts 18: 1- 4, 20 : 33-35</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2012:12-27');">
        I Corinthians 12 : 12-27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Thessalonians%204:9%20-12');">
        I Thessalonians 4: 9 -12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Thessalonians%203:6-15');">
        II Thessalonians 3: 6-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%206:1-6');">
        St. Mark 6: 1-6</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">January-30: Monday of Three days Lent</h3>
        <div style="page-break-inside: avoid" id="025" name="025">
        <div class="MsgTit" id="025Txt">Description<div class="MsgTxt">
        
        The three day lent is a lent of attrition and repentance commemorating
        
        the repentance of the people of Nineveh at the preaching of Prophet Jonah.
        
        This lent starts three weeks before the start of the Great Lent.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2012:31-41');">
        St. Matthew 12: 31-41</a></div></div>Morning<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%205:5-10');">
        Numbers 5: 5-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jonah%201:1-%2017');">
        Jonah 1: 1- 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2019:17-22');">
        Isaiah 19: 17-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2013:6%20-%2012');">
        Acts 13:6 - 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Colossians%201:3-13');">
        Colossians 1: 3-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%207:1-12');">
        St. Matthew 7: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-2: Mayaltho (Entry of our Lord into the temple)</h3>
        <div style="page-break-inside: avoid" id="023" name="023">
        <div class="MsgTit" id="023Txt">Description<div class="MsgTxt">
        
        February 2nd is celebrated as the day when infant
        
        Jesus was presented in the temple. Also called the day
        
        of the Old Aged. Memory of St. Simon and St. Hanna.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%202:22-%2040');">
        St. Luke 2: 22- 40</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%202:22-40');">
        St. Luke 2: 22-40</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2012:1-8,19:30-37');">
        Leviticus 12: 1-8, 19: 30-37</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Proverbs%206:20%20-%2024,23:22-26');">
        Proverbs 6 : 20 - 24 , 23 : 22-26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2011:1%20-%2010');">
        Isaiah 11: 1 - 10</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2024:10-23');">
        Acts 24: 10-23</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%203:13-21,6:1-4');">
        Ephesians 3: 13-21, 6: 1-4</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%202:22%20-40');">
        St. Luke 2: 22 -40</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-2: Thursday/The end of Three days Lent</h3>
        <div style="page-break-inside: avoid" id="028" name="028">
        <div class="MsgTit" id="028Txt">Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2018:23-33');">
        Genesis 18: 23-33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%207:6-%2020');">
        Joshua 7: 6- 20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jonah%204:1%20-%2011');">
        Jonah 4: 1 - 11</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('James%204:7-17');">
        St. James 4: 7-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%205:3-21');">
        Ephesians 5: 3-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2012:54-13:9');">
        St. Luke 12: 54-13: 9</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-3: All Departed Holy Fathers</h3>
        <div style="page-break-inside: avoid" id="024" name="024">
        <div class="MsgTit" id="024Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2013:22-30');">
        St. Luke 13: 22-30</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%205:1-20');">
        St. Matthew 5: 1-20</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2039:11-23');">
        Genesis 39: 11-23</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Lamentations%203:1-33');">
        Lamentations 3: 1-33</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('James%201:12-21');">
        St. James 1: 12-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Timothy%203:10-15');">
        II Timothy 3: 10-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%206:20-31');">
        St. Luke 6: 20-31</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-5: All Departed Priests</h3>
        <div style="page-break-inside: avoid" id="029" name="029">
        <div class="MsgTit" id="029Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2025:13-30');">
        St. Matthew 25: 13-30</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2019:11-28');">
        St. Luke 19: 11-28</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2020:23-29');">
        Numbers 20: 23-29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2034:1-8');">
        Deuteronomy 34: 1-8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2065:13-20');">
        Isaiah 65: 13-20</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2020:26-38');">
        Acts 20: 26-38</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Thessalonians%204:13-5:11');">
        I Thessalonians 4: 13-5: 11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2024:42-51');">
        St. Matthew 24: 42-51</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-12: All the Departed Faithful</h3>
        <div style="page-break-inside: avoid" id="030" name="030">
        <div class="MsgTit" id="030Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2025:31-46');">
        St. Matthew 25: 31-46</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%205:19-29');">
        St. John 5: 19-29</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2049:33-50:13');">
        Genesis 49: 33-50: 13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2037:1-14');">
        Ezekiel 37: 1-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2038:10-20');">
        Isaiah 38: 10-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Maccabees%2012:38-45');">
        II Maccabees 12: 38-45</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('James%203:1%20-%2012');">
        St. James 3: 1 - 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2015:20-28');">
        I Corinthians 15: 20-28</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2012:32-48');">
        St. Luke 12: 32-48</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-19: First Sunday of 50 days Lent (Kothine Sunday - Pethrutha of the Great Lent)</h3>
        <div style="page-break-inside: avoid" id="031" name="031">
        <div class="MsgTit" id="031Txt">Description<div class="MsgTxt">
        
        The Great Lent starts by commemorating the first miracle performed by Jesus i.e.
        
        turning water into wine at the wedding feast at Cana of Galilee. The Gospel reading
        
        for each Sunday of the Great Lent is about a miracle performed by Jesus.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%203:1-%206');">
        St. John 3: 1- 6</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2016:1-18');">
        St. Luke 16: 1-18</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2020:1-21');">
        Exodus 20: 1-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joel%202:12-20');">
        Joel 2: 12-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2058:5-14');">
        Isaiah 58: 5-14</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2011:19-26');">
        Acts 11: 19-26</a></div>
        <div class="MsgTxt">
        OR</div>
        <div class="MsgTxt"><a href="javascript:openBible('Revelations%202:1%20-7');">
        Revelations 2: 1 -7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Colossians%203:1-%2017');">
        Colossians 3: 1- 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%202:1-11');">
        St. John 2: 1-11</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">February-26: Second Sunday of Fifty days Lent (Lepers' Sunday)</h3>
        <div style="page-break-inside: avoid" id="038" name="038">
        <div class="MsgTit" id="038Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%201:32-45');">
        St. Mark 1 : 32-45</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%209:14%20-%2029');">
        St. Mark 9 : 14 - 29</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%207:6%20-%2024');">
        Genesis 7: 6 - 24</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%205:1%20-%2014');">
        II Kings 5: 1 - 14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2033:2-9');">
        Isaiah 33: 2-9</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%209:22-31');">
        Acts 9: 22-31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%203:27-%204:5');">
        Romans 3: 27- 4: 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%205:12-16,4:40-41');">
        St. Luke 5: 12-16, 4: 40-41</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-5: Third Sunday of Fifty days Lent (Palsy Sunday)</h3>
        <div style="page-break-inside: avoid" id="045" name="045">
        <div class="MsgTit" id="045Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%205:17-26');">
        St. Luke 5: 17-26</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%205:1-18');">
        St. John 5: 1-18</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2033:23-34:12');">
        Ezekiel 33: 23-34: 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%204:10%20-%2017');">
        Exodus 4 : 10 - 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%202:1%20-%2011');">
        II Kings 2 : 1 - 11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%205:20-25');">
        Isaiah 5: 20-25</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%205:12-16,19:8%20-12');">
        Acts 5: 12-16, 19: 8 -12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%205:1-11');">
        Romans 5: 1-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%202:1-12');">
        St. Mark 2: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-11: Memory of Forty Martyrs</h3>
        <div style="page-break-inside: avoid" id="073" name="073">
        <div class="MsgTit" id="073Txt">Description<div class="MsgTxt">
        
        Memorial of the 40 martyrs of Sebeste.  Celebrated on 9th March, if it is a Saturday or Sunday. Otherwise on the Saturday after 9th
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2010:16-33');">
        St. Matthew 10: 16-33</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2012:1-12');">
        St. Luke 12: 1-12</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%204:1:12');">
        Genesis 4: 1: 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2030:1-4');">
        Isaiah 30: 1-4</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Daniel%203:14%20-25');">
        Daniel 3: 14 -25</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%204:19-33');">
        Acts 4: 19-33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%208:31%20-%2039');">
        Romans 8: 31 - 39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%208:34-38');">
        St. Mark 8: 34-38</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-12: Fourth Sunday of Fifty days Lent (Canaanite woman)</h3>
        <div style="page-break-inside: avoid" id="052" name="052">
        <div class="MsgTit" id="052Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%207:24-37');">
        St. Mark 7: 24-37</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%207:1-10');">
        St. Luke 7: 1-10</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%207:10-17');">
        I Samuel 7: 10-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2017:1%20-%208');">
        Numbers 17 : 1 - 8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2056:1%20-%207');">
        Isaiah 56: 1 - 7</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%204:1-%2012');">
        Acts 4 : 1- 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%207:14%20-%2025');">
        Romans 7: 14 - 25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2015:21-28');">
        Matthew 15: 21-28</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-15: Fourth Wednesday of Fifty days Lent (Mid Lent)</h3>
        <div style="page-break-inside: avoid" id="055" name="055">
        <div class="MsgTit" id="055Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2017:22-27');">
        St. Matthew 17: 22-27</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%203:13-21');">
        St. John 3: 13-21</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2021:4-9');">
        Numbers 21: 4-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2034:1-9');">
        Psalms 34: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%2012:6-14');">
        Zechariah 12: 6-14</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2015:22%20-33');">
        Acts 15: 22 -33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Corinthians%209:1-15');">
        II Corinthians 9: 1-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%203:13-21');">
        St. John 3: 13-21</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-19: Fifth Sunday of Fifty days Lent (Kpiptho/Crippled Woman)</h3>
        <div style="page-break-inside: avoid" id="059" name="059">
        <div class="MsgTit" id="059Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2010:25-37');">
        St. Luke 10: 25-37</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%207:11-17');">
        St. Luke 7: 11-17</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%209:1%20-7');">
        Genesis 9: 1 -7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2051:1-9');">
        Jeremiah 51: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2050:1%20-%205');">
        Isaiah 50: 1 - 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Barazeera%2051:13%20-%2030');">
        Barazeera 51: 13 - 30</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:8-16');">
        I Peter 3: 8-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%2012:1-15');">
        Romans 12: 1-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2013:10-17');">
        St. Luke 13: 10-17</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-25: Annunciation to St. Mary</h3>
        <div style="page-break-inside: avoid" id="074" name="074">
        <div class="MsgTit" id="074Txt">Description<div class="MsgTxt">
        
        We celebrate the Feast of Annunciation to St. Mary twice a year.
        
        On the 3rd Sunday after Koodhosh Eetho and on March 25th.
        
        Holy Eucharist must be celebrated on this day even if it comes
        
        during the Great Lent, during the Passion Week or on Good Friday. (The Holy
        
        Eucharist is not celebrated during the Great Lent days except all Saturdays and Sundays,
        
        Wednesday of Mid Lent, 40th Friday, and Maundy Thursday
        
        during the Passion week.)
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%208:16-21');">
        St. Luke 8 : 16-21</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%203:31-35');">
        St. Mark 3: 31-35</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2026:16-27:10');">
        Deuteronomy 26: 16-27: 10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Judges%2013:2-14');">
        Judges 13: 2-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%202:10-13,4:1-7,8:3');">
        Zechariah 2: 10-13, 4: 1-7, 8: 3</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2063:15-64:5');">
        Isaiah 63: 15-64: 5</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%203:2-17');">
        I John 3: 2-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%206:13-7:10');">
        Hebrews 6: 13-7: 10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%201:26-38');">
        St. Luke 1: 26-38</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-26: Sixth Sunday of Fifty Days Lent (Catholicate Day)</h3>
        <div style="page-break-inside: avoid" id="066" name="066">
        <div class="MsgTit" id="066Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:46%20-%2052');">
        St. Mark 10 : 46 - 52</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%209:27-31');">
        St. Matthew 9 : 27-31</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2025:13-%2016,26:1%20-%2013');">
        Deuteronomy 25: 13- 16, 26:1 - 13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Job%2042:1-10');">
        Job 42: 1-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Malachi%203:7-12');">
        Malachi 3:7-12</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%204:12-19');">
        I Peter 4: 12-19</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Corinthians%209:6-15/');">
        II Corinthians 9 : 6-15/</a></div>
        <div class="MsgTxt">
        or/</div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%205:1-14');">
        Ephesians 5: 1-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%209:1-41');">
        St. John 9: 1-41</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">March-31: Friday before Hosanna (40th Friday)</h3>
        <div style="page-break-inside: avoid" id="071" name="071">
        <div class="MsgTit" id="071Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%204:1-13');">
        St. Luke 4: 1-13</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%204:1-11');">
        St. Matthew 4: 1-11</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%201:3%20-%2014');">
        Deuteronomy 1: 3 - 14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Samuel%2024:18-25');">
        II Samuel 24: 18-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2058:1-8');">
        Isaiah 58: 1-8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%201:13%20-%2022');">
        I Peter 1: 13 - 22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%2013:11%20-%2014:9');">
        Romans 13: 11 - 14: 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%204:1-11');">
        St. Matthew 4: 1-11</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-1: Saturday before Hosanna (Lazarus' Saturday)</h3>
        <div style="page-break-inside: avoid" id="072" name="072">
        <div class="MsgTit" id="072Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2010:38%20-%2042');">
        St. Luke 10 : 38 - 42</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2011:1%20-%208,11%20-%2027');">
        St. John 11: 1 - 8, 11 - 27</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2027:30%20-%2033');">
        Leviticus 27 : 30 - 33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2061:1-9');">
        Isaiah 61: 1-9</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:6-10');">
        I Peter 2: 6-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%201:15-2:7');">
        Ephesians 1: 15-2: 7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2011:28-46');">
        St. John 11: 28-46</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-2: Hosanna/Palm Sunday - (Boys' and Girl's Day)</h3>
        <div style="page-break-inside: avoid" id="075" name="075">
        <div class="MsgTit" id="075Txt">Evening<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2021:1%20-%2017');">
        St. Matthew 21: 1 - 17</a></div>
        <div class="MsgTxt">
        Midnight</div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2011:1-%2011');">
        St. Mark 11: 1- 11</a></div>
        </div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2019:28%20-%2044');">
        St. Luke 19 : 28 - 44</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2049:8-15');">
        Genesis 49: 8-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%202:18%20-26,16:1');">
        I Samuel 2: 18 -26, 16 : 1</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Daniel%201:16%20-%2020');">
        Daniel 1: 16 - 20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Micah%204:1%20-5');">
        Micah 4: 1 -5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Kings%203:4%20-%2014');">
        I Kings 3: 4 - 14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zephaniah%203:11%20-20');">
        Zephaniah 3: 11 -20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2030:18-22');">
        Jeremiah 30: 18-22</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%205:1%20-12');">
        I John 5 : 1 -12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%208:18%20-25');">
        Romans 8 : 18 -25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2012:12-19');">
        St. John 12: 12-19</a></div>
        </div>For Procession (at the Western Entrance)<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2019:28-40');">
        St. Luke 19: 28-40</a></div></div>For the Blessing of the Palm leaves<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%209:9-12');">
        Zechariah 9: 9-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2051:9-11');">
        Isaiah 51: 9-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%202:7-14');">
        I John 2: 7-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%2011:13-24');">
        Romans 11: 13-24</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2011:1-11');">
        St. Mark 11: 1-11</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-6: Pesaho (Maundy Thursday)</h3>
        <div style="page-break-inside: avoid" id="079" name="079">
        <div class="MsgTit" id="079Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%207:45-52,8:12-20');">
        St. John 7: 45-52, 8: 12-20</a></div></div>Midnight<div class="MsgTxt"></div>First Qaumo<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2026:1%20-30');">
        St. Matthew 26: 1 -30</a></div></div>Second Qaumo<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:24-%204%200');">
        St. John 6: 24- 4 0</a></div></div>Third Qaumo<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:41-63');">
        St. John 6: 41-63</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2022:1-13');">
        St. Luke 22: 1-13</a></div></div>Third Hour (9 a.m.)<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2012:20-36');">
        St. John 12: 20-36</a></div></div>Noon (12 p.m.)<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Luke%207:36%20-50');">
        St. Luke 7 : 36 -50</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2014:1-%202');">
        St. Mark 14: 1- 2</a></div>
        </div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2012:1-11');">
        Exodus 12: 1-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2016:3-10');">
        Leviticus 16: 3-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2045:18%20-%2025');">
        Ezekiel 45: 18 - 25</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%201:15-20');">
        Acts 1: 15-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%205:1%20-8%2011:23%20-%2034');">
        I Corinthians 5 : 1 -8 11: 23 - 34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2022:14-30');">
        St. Luke 22: 14-30</a></div>
        </div>Ninth Hour (3 p.m.)<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2026:31-%2035');">
        St. Matthew 26: 31- 35</a></div></div>For Feet Washing Service<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2034:18-26');">
        Exodus 34: 18-26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%2023:21%20-%2025');">
        II Kings 23: 21 - 25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2050:4-10');">
        Isaiah 50: 4-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:17-22');">
        I Peter 3: 17-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%2010:19%20-29');">
        Hebrews 10: 19 -29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2013:1-20');">
        St. John 13: 1-20</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-7: Good Friday</h3>
        <div style="page-break-inside: avoid" id="080" name="080">
        <div class="MsgTit" id="080Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2022:1-30');">
        St. Luke 22: 1-30</a></div></div>Midnight<div class="MsgTxt"></div>First Qauma<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2026:31-46');">
        St. Matthew 26: 31-46</a></div></div>Second Qauma<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2014:27-52');">
        St. Mark 14: 27-52</a></div></div>Third Qauma<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2022:31-62');">
        St. Luke 22: 31-62</a></div></div>Morning<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%204:1-7,16;3%20-%2034');">
        Leviticus 4: 1-7, 16; 3 - 34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2019:1-11');">
        Numbers 19: 1-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2022:30-23:16');">
        Acts 22: 30-23: 16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%201:18-31');">
        I Corinthians 1: 18-31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%209:11%20-14');">
        Hebrews 9: 11 -14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:3%20-10');">
        St. Matthew 27: 3 -10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2015:1-10');">
        St. Mark 15: 1-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2022:66-71');">
        St. Luke 22: 66-71</a></div>
        </div>Third Hour (9 a.m.)<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:12%20-%2031');">
        St. Matthew 27: 12 - 31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2015:12%20-%2018');">
        St. Mark 15: 12 - 18</a></div>
        </div>Noon (12 p.m.)<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2023:26%20-34');">
        St. Luke 23: 26 -34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:34');">
        St. Matthew 27:34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:23-24');">
        St. John 19:23-24</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:36-%2037');">
        St. Matthew 27: 36- 37</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2023:39%20-45');">
        St. Luke 23: 39 -45</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:25-%2027');">
        St. John 19: 25- 27</a></div>
        </div>Ninth Hour (3 p.m.)<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:45-56');">
        St. Matthew 27: 45-56</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2015:33-41');">
        St. Mark 15: 33-41</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2023:44-49');">
        St. Luke 23: 44-49</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:23-30');">
        St. John 19: 23-30</a></div>
        </div>Veneration of the Holy Cross<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2022:1-14');">
        Genesis 22: 1-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2017:8-14');">
        Exodus 17: 8-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2052:13-53:8');">
        Isaiah 52: 13-53: 8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:19-25');">
        I Peter 2: 19-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%202:20%20-%203:14,6:11-18');">
        Galatians 2: 20 - 3: 14, 6: 11-18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2023:55%20-%2056');">
        St. Luke 23: 55 - 56</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:31%20-%2042');">
        St. John 19: 31 - 42</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-8: Gospel Saturday (Saturday of Good Tidings)</h3>
        <div style="page-break-inside: avoid" id="081" name="081">
        <div class="MsgTit" id="081Txt">Evening<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:61');">
        St.Matthew 27: 61</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2023:50-24:12');">
        St. Luke 23: 50-24: 12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:31-42');">
        St. John 19: 31-42</a></div>
        </div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:62-66');">
        St. Matthew 27:62-66</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%208:1-12');">
        Genesis 8: 1-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%2012:11-13:7');">
        Zechariah 12: 11-13: 7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2038:2-13');">
        Jeremiah 38: 2-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Micah%207:8%20-13');">
        Micah 7 : 8 -13</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:13%20-22');">
        I Peter 3: 13 -22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%206:1-14');">
        Romans 6: 1-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2027:62%20-%2066');">
        St. Matthew 27: 62 - 66</a></div>
        </div>Shub-khono<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%204:11-21');">
        I John 4: 11-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2013:4-10');">
        I Corinthians 13: 4-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2018:12-%2035');">
        St. Matthew 18: 12- 35</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-9: Easter Sunday</h3>
        <div style="page-break-inside: avoid" id="082" name="082">
        <div class="MsgTit" id="082Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2016:1-%208');">
        St. Mark 16: 1- 8</a></div></div>Midnight<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2024:1-12');">
        St. Luke 24: 1-12</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2020:1-18');">
        St. John 20: 1-18</a></div></div>Celebration of the Holy Cross<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2060:17-22');">
        Isaiah 60: 17-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%205:5-14');">
        I Peter 5: 5-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%2016:1-16');">
        Romans 16: 1-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2014:27,15:11-15,17-19');">
        St. John 14: 27, 15: 11-15, 17-19</a></div>
        </div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2023:26%20-%2032');">
        Leviticus 23: 26 - 32</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2060:1-7,11-16,61:10-62:5');">
        Isaiah 60: 1-7, 11-16, 61: 10-62: 5</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%202:22%20-36');">
        Acts 2: 22 -36</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2015:1-%2019');">
        I Corinthians 15: 1- 19</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2028:1-20');">
        St. Matthew 28: 1-20</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-16: New Sunday (Sunday after Easter - The Sunday of the Youth)</h3>
        <div style="page-break-inside: avoid" id="089" name="089">
        <div class="MsgTit" id="089Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2020:19-31');">
        St. John 20: 19-31</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%204:31%20-38');">
        St. John 4:31 -38</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2041:41-46');">
        Genesis 41: 41-46</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ecclesiastes%2012:1%20-%208');">
        Ecclesiastes 12:1 - 8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%201:4-12');">
        Jeremiah 1: 4-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2040:9-15');">
        Isaiah 40: 9-15</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:19-25');">
        I Peter 2: 19-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Timothy%202:1%20-%2013');">
        II Timothy 2: 1 - 13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2020:19-31');">
        St. John 20: 19-31</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-23: First Sunday after New Sunday</h3>
        <div style="page-break-inside: avoid" id="090" name="090">
        <div class="MsgTit" id="090Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2021:15%20-1');">
        St. John 21: 15 -1</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2021:1-%2014');">
        St. John 21: 1- 14</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2014:26-31');">
        Exodus 14: 26-31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%206:9%20-%2021');">
        Joshua 6: 9 - 21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Great%20Wisdom%201:1-8');">
        Great Wisdom 1: 1-8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2013:26-39');">
        Acts 13: 26-39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%206:10-20');">
        Ephesians 6: 10-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2021:1%20-%2014');">
        St. John 21: 1 - 14</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">April-30: Second Sunday after New Sunday</h3>
        <div style="page-break-inside: avoid" id="091" name="091">
        <div class="MsgTit" id="091Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:16-29');">
        St. John 6: 16-29</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2014:22-33');">
        St. Matthew 14: 22-33</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2040:1-16');">
        Exodus 40: 1-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%202:1-6');">
        Joshua 2: 1-6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2049:13%20-21');">
        Isaiah 49 : 13 -21</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%204:8-21');">
        Acts 4: 8-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%203:1-13');">
        Hebrews 3: 1-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2021:15%20-19');">
        St. John 21: 15 -19</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">May-7: Third Sunday after New Sunday</h3>
        <div style="page-break-inside: avoid" id="092" name="092">
        <div class="MsgTit" id="092Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%205:27-39');">
        St. Luke 5: 27-39</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:47-58');">
        St. John 6 : 47-58</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2034:4-12');">
        Exodus 34: 4-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Micah%204:1-7');">
        Micah 4: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zachariah%208:4-9');">
        Zachariah 8: 4-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2037:8-17');">
        Isaiah 37: 8-17</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%205:13%20-%2021');">
        I John 5: 13 - 21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%2011:3-6');">
        Hebrews 11: 3-6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2024:13%20-%2035');">
        St. Luke 24 : 13 - 35</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">May-14: Fourth Sunday after New Sunday</h3>
        <div style="page-break-inside: avoid" id="093" name="093">
        <div class="MsgTit" id="093Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2014:1-16');">
        St. John 14: 1-16</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2016:16-30');">
        St. John 16: 16-30</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2016:1-8');">
        Deuteronomy 16: 1-8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%208:30-35');">
        Joshua 8: 30-35</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2054:1-8');">
        Isaiah 54 : 1-8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:17-22');">
        I Peter 3: 17-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%2011:32-40');">
        Hebrews 11: 32-40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%209:51%20-%2062');">
        St. Luke 9 : 51 - 62</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">May-18: Ascension of our Lord (Thursday after the fourth Sunday following New Sunday)</h3>
        <div style="page-break-inside: avoid" id="094" name="094">
        <div class="MsgTit" id="094Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2016:14%20-%2020');">
        St. Mark 16: 14 - 20</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2024:36-53');">
        St. Luke 24: 36-53</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%209:26-10:5');">
        Deuteronomy 9: 26-10: 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2031:31-34');">
        Jeremiah 31: 31-34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%202:9-15');">
        II Kings 2: 9-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2048:20-49:4');">
        Isaiah 48: 20-49: 4</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%201:4%20-11');">
        Acts 1: 4 -11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%204:1-16');">
        Ephesians 4: 1-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2024:36%20-%2053');">
        St. Luke 24 : 36 - 53</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">May-21: Sunday before Pentecost (Sunday of the Monks)</h3>
        <div style="page-break-inside: avoid" id="095" name="095">
        <div class="MsgTit" id="095Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2019:16%20-%2030');">
        St. Matthew 19 : 16 - 30</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:17%20-%2022');">
        St. Mark 10 : 17 - 22</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2025:1%20-%2013');">
        Leviticus 25: 1 - 13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%201:5-9');">
        Joshua 1: 5-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2044:23-28');">
        Isaiah 44: 23-28</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2021:7%20-%209');">
        Acts 21 : 7 - 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%207:1-%202,25%20-%2034,9:1-%2010');">
        I Corinthians 7: 1- 2, 25 - 34, 9: 1- 10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2017:13%20-26');">
        St. John 17: 13 -26</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">May-28: Pentecost (Fiftieth day after Easter) (Sunday School Day)</h3>
        <div style="page-break-inside: avoid" id="096" name="096">
        <div class="MsgTit" id="096Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2014:15-31');">
        St. John 14:15-31</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2015:20%20-25');">
        St. John 15: 20 -25</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2011:16%20-%2035');">
        Numbers 11: 16 - 35</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%2010:9-12');">
        I Samuel 10:9-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%202:14-17,12:1-27');">
        II Kings 2: 14-17 , 12: 1-27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2011:17-20,36:25%20-%2027');">
        Ezekiel 11: 17-20, 36: 25 - 27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2042:1-%2027');">
        Isaiah 42: 1- 27</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%202:1-13');">
        Acts 2: 1-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%205:16%20-%2026');">
        Galatians 5 : 16 - 26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2015:1%20-%2014');">
        St. John 15: 1 - 14</a></div>
        </div>Service of the First kneeling<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2011:1-9');">
        Genesis 11: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%202:14%20-%2017');">
        II Kings 2: 14 - 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2019:1-7');">
        Acts 19:1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2014:20-25');">
        I Corinthians 14: 20-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2014:1-17');">
        St. John 14: 1-17</a></div>
        </div>Service of the Second Kneeling<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2037:1%20-%2014');">
        Ezekiel 37: 1 - 14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joel%202:25-32');">
        Joel 2:25-32</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2010:34%20-%2048');">
        Acts 10: 34 - 48</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2012:12-%2027');">
        I Corinthians 12: 12- 27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2014:25%20-%2031');">
        St. John 14: 25 - 31</a></div>
        </div>Service of the Third Kneeling<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Judges%2013:24-%2014:7');">
        Judges 13 : 24- 14: 7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2047:1-12');">
        Ezekiel 47: 1-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2047:1-%201');">
        Isaiah 47: 1- 1</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Acts%202:1-21');">
        Acts 2: 1-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2014:20-%2033');">
        I Corinthians 14: 20- 33</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2016:1%20-%2015');">
        St. John 16: 1 - 15</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">June-4: First Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="098" name="098">
        <div class="MsgTit" id="098Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%208:4-15');">
        St. Luke 8: 4-15</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2011:20-%2024');">
        St. Matthew 11: 20- 24</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2041:38-40');">
        Genesis 41: 38-40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2012:31-40');">
        Exodus 12: 31-40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Joshua%205:9-12');">
        Joshua 5: 9-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2029:10-16');">
        Jeremiah 29: 10-16</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2017:10-15');">
        Acts 17: 10-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Corinthians%205:14%20-%206:10');">
        II Corinthians 5: 14 - 6:10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%206:26-35');">
        St. John 6: 26-35</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">June-11: Second Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="099" name="099">
        <div class="MsgTit" id="099Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%206:12-23');">
        St. Luke 6: 12-23</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%203:7%20-19');">
        St. Mark 3: 7 -19</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2019:1-8');">
        Leviticus 19: 1-8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Samuel%205:1-10');">
        II Samuel 5: 1-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Daniel%206:25-28');">
        Daniel 6: 25-28</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%204:23%20-%2031');">
        Acts 4: 23 - 31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%202:11-22');">
        Ephesians 2: 11-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2010:5%20-%2016');">
        St. Matthew 10: 5 - 16</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">June-18: Third Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="100" name="100">
        <div class="MsgTit" id="100Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%206:4-13');">
        St. Mark 6: 4-13</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%209:1-11');">
        St. Luke 9: 1-11</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2042:18-25');">
        Genesis 42: 18-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%203:1%20-%205');">
        Jeremiah 3: 1 - 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Daniel%203:21-30');">
        Daniel 3: 21-30</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2013:26-39');">
        Acts 13: 26-39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%206:10-18');">
        Galatians 6:10-18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%206:35-46');">
        St. John 6: 35-46</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">June-25: Fourth Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="101" name="101">
        <div class="MsgTit" id="101Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2014:14%20-%2023');">
        St. Matthew 14: 14 - 23</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2010:17-24');">
        St. Luke 10: 17-24</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2033:23-29');">
        Deuteronomy 33: 23-29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2015');">
        Psalms 15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2065:8-12');">
        Isaiah 65: 8-12</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%206:1-7');">
        Acts 6: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2016:14-%2022');">
        I Corinthians 16 : 14- 22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2010:1%20-%2016');">
        St. Luke 10 : 1 - 16</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">June-29: Feast of St. Peter and St. Paul</h3>
        <div style="page-break-inside: avoid" id="109" name="109">
        <div class="MsgTit" id="109Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2016:13-20');">
        St.Matthew 16: 13-20</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2021:15%20-%2025');">
        St. John 21: 15 - 25</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2012:1-9');">
        Genesis 12: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Daniel%201:8-%2021');">
        Daniel 1: 8- 21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2043:1-%207');">
        Isaiah 43: 1- 7</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%201:12-14');">
        Acts 1: 12-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2012:28-13:10');">
        I Corinthians 12: 28-13: 10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2020:1-16');">
        St. Matthew 20: 1-16</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-2: Fifth Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="102" name="102">
        <div class="MsgTit" id="102Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%209:10%20-%2017');">
        St. Luke 9: 10 - 17</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2017:20%20-%2028');">
        St. Matthew 17 : 20 - 28</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2023:14-19');">
        Exodus 23: 14-19</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2016');">
        Psalms 16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2040:27-31');">
        Isaiah 40: 27-31</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%209:10-18');">
        Acts 9: 10-18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Corinthians%205:14-20');">
        II Corinthians 5: 14-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%209:10-17');">
        St. Luke 9:10-17</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-3: Dukharono of St.Thomas (July 3rd)</h3>
        <div style="page-break-inside: avoid" id="152" name="152">
        <div class="MsgTit" id="152Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2012:13-21');">
        St.Luke 12: 13-21</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2012:48-59');">
        St.Luke 12: 48-59</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2012:43-51');">
        Exodus 12: 43-51</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Job%2023:1-7');">
        Job 23: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2052:1-15');">
        Isaiah 52 :1-15</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('1%20Peter%202:15-17');">
        1 Peter 2:15-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('1%20Corinthians%206:9-11');">
        1 Corinthians 6: 9-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mathew%205:21-26');">
        St.Mathew 5: 21-26</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-9: Sixth Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="103" name="103">
        <div class="MsgTit" id="103Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2017:1-10');">
        St. Luke 17: 1-10</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%206:30-%2052');">
        St. Mark 6: 30- 52</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2030:34-38');">
        Exodus 30: 34-38</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2020');">
        Psalms 20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2014:22-27');">
        Isaiah 14: 22-27</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%201:15-%2020');">
        Acts 1: 15- 20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%208:1-6');">
        I Corinthians 8: 1-6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2015:32-39');">
        St. Matthew 15: 32-39</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-16: Seventh Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="104" name="104">
        <div class="MsgTit" id="104Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2012:30-37');">
        St. Matthew 12: 30-37</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2012:38-50');">
        St. Matthew 12: 38-50</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%2016:29-34');">
        Leviticus 16: 29-34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hosea%205:1-5');">
        Hosea 5: 1-5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2057:15-19');">
        Isaiah 57: 15-19</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%204:32-37');">
        Acts 4: 32-37</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%202:11-22');">
        Ephesians 2: 11-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%203:20-30');">
        St. Mark 3: 20-30</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-23: Eighth Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="105" name="105">
        <div class="MsgTit" id="105Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%204:1-23');">
        St. Mark 4: 1-23</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2013:10-23');">
        St. Matthew 13: 10-23</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%203:9-15');">
        Exodus 3: 9-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%207:21-25');">
        Jeremiah 7:21-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2052:1-6');">
        Isaiah 52: 1-6</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:4%20-10');">
        I Peter 2: 4 -10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%204:14-5:5');">
        Hebrews 4: 14-5: 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%206:47%20-%2059');">
        St. John 6 : 47 - 59</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">July-30: Ninth Sunday after Pentecost</h3>
        <div style="page-break-inside: avoid" id="106" name="106">
        <div class="MsgTit" id="106Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2013:24-35');">
        St. Matthew 13: 24-35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2013:36-43');">
        St. Matthew 13: 36-43</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2022:1%20-%2021');">
        Psalms 22 : 1 - 21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%204:1-6');">
        Deuteronomy 4: 1-6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Proverbs%2013:1-7');">
        Proverbs 13: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2024:1-5');">
        Isaiah 24: 1-5</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2028:11-22');">
        Acts 28: 11-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%206:1-11');">
        I Corinthians 6: 1-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2014:7-11');">
        St. Luke 14: 7-11</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">August-6: The Festival of Transfiguration/Koodaara Perunnal</h3>
        <div style="page-break-inside: avoid" id="111" name="111">
        <div class="MsgTit" id="111Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2016:27-17:13');">
        St. Matthew 16: 27-17: 13</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%208:38-9:13');">
        St. Mark 8: 38-9: 13</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2016:13-17');">
        Deuteronomy 16: 13-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2024');">
        Psalms 24</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2060:12-15,61:1-3');">
        Isaiah 60: 12-15, 61: 1-3</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%202:23-3:1');">
        I John 2: 23-3: 1</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%2011:25-36');">
        Romans 11: 25-36</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%209:27-36');">
        St. Luke 9: 27-36</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">August-13: First Sunday after the Festival of Transfiguration</h3>
        <div style="page-break-inside: avoid" id="112" name="112">
        <div class="MsgTit" id="112Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%204:21-34');">
        St. Mark 4: 21-34</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2013:44-52');">
        St. Matthew 13: 44-52</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Deuteronomy%2025:13-16');">
        Deuteronomy 25: 13-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2027');">
        Psalms 27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%207:1-4');">
        Ezekiel 7: 1-4</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('James%204:7-5:6');">
        St. James 4: 7-5: 6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Philippians%204:8-20');">
        Philippians 4: 8-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2021:28-32');">
        St. Matthew 21: 28-32</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">August-15: The Festival of the Assumption of St. Mary (Marth Mariam Samajam Day)</h3>
        <div style="page-break-inside: avoid" id="114" name="114">
        <div class="MsgTit" id="114Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%201:39%20-%2056,2:22%20-%2035');">
        St. Luke 1: 39 - 56, 2: 22 - 35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%202:42-51,');">
        St. Luke 2: 42-51,</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%203:1%20-%206,19:16-23');">
        Exodus 3: 1 - 6, 19: 16-23</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2044:1%20-%203');">
        Ezekiel 44 : 1 - 3</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2045:11%20-19');">
        Isaiah 45: 11 -19</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%201:12%20-%2014,7:44-53');">
        Acts 1: 12 - 14, 7: 44-53</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%209:3-12,2:14%20-%2018');">
        Hebrews 9: 3-12, 2: 14 - 18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2011:22%20-28');">
        St. Luke 11 : 22 -28</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2012:46%20-%2050');">
        St. Matthew 12: 46 - 50</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2019:25-27');">
        St. John 19: 25-27</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">August-20: First Sunday after the Festival of the Assumption</h3>
        <div style="page-break-inside: avoid" id="115" name="115">
        <div class="MsgTit" id="115Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2014:25-35');">
        St. Luke 14: 25-35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2015:1-10');">
        St. Luke 15: 1-10</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2014:21-31');">
        Exodus 14: 21-31</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%208:4%20-9');">
        I Samuel 8: 4 -9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2043:1-5');">
        Isaiah 43: 1-5</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%202:22-29');">
        I John 2: 22-29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%206:10-17');">
        Ephesians 6: 10-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%206:39%20-%2045');">
        St. Luke 6 : 39 - 45</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">August-27: Second Sunday after the Festival of the Assumption</h3>
        <div style="page-break-inside: avoid" id="116" name="116">
        <div class="MsgTit" id="116Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2018:1%20-%208');">
        St. Luke 18: 1 - 8</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2018:9-17');">
        St. Luke 18: 9-17</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%206:3-12');">
        Genesis 6: 3-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ecclesiastes%207:1-7');">
        Ecclesiastes 7: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2012:1-%207');">
        Psalms 12: 1- 7</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('II%20Peter%203:8-14');">
        II Peter 3: 8-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Thessalonians%205:1-%2011');">
        I Thessalonians 5: 1- 11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2011:9%20-20');">
        St. Luke 11: 9 -20</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">September-3: Third Sunday after the Festival of the Assumption</h3>
        <div style="page-break-inside: avoid" id="117" name="117">
        <div class="MsgTit" id="117Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2019:1-12');">
        St. Matthew 19: 1-12</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2019:11-27');">
        St. Luke 19: 11-27</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2024:12-18');">
        Exodus 24: 12-18</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%203:16-21');">
        I Samuel 3: 16-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ezekiel%2018:21-24');">
        Ezekiel 18: 21-24</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('James%205:1-6');">
        St. James 5: 1-6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Corinthians%2010:1-7');">
        II Corinthians 10: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2017:22-27');">
        St. Matthew 17: 22-27</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">September-10: Fourth Sunday after the Festival of the Assumption</h3>
        <div style="page-break-inside: avoid" id="118" name="118">
        <div class="MsgTit" id="118Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2018:1-11');">
        St. Matthew 18: 1-11</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2018:12-22');">
        St. Matthew 18: 12-22</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%203:1-6,11-14');">
        Exodus 3: 1-6, 11-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Job%201:1-5');">
        Job 1: 1-5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%201:15%20-20');">
        Isaiah 1: 15 -20</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:1-5');">
        I Peter 2: 1-5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%203:16-23');">
        I Corinthians 3: 16-23</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%205:38%20-%2048');">
        St. Matthew 5 : 38 - 48</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">September-14: The Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="120" name="120">
        <div class="MsgTit" id="120Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2024:1-35');">
        St. Matthew 24: 1-35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2013:1-33');">
        St. Mark 13: 1-33</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2021:4%20-%209');">
        Numbers 21: 4 - 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%2017:37-53');">
        I Samuel 17: 37-53</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Jeremiah%2032:36%20-%2041');">
        Jeremiah 32 : 36 - 41</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2013:26-39');">
        Acts 13: 26-39</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%202:17-3:14');">
        Galatians 2: 17-3: 14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2021:5%20-%2028');">
        St. Luke 21: 5 - 28</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">September-17: First Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="121" name="121">
        <div class="MsgTit" id="121Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2017:20-37');">
        St. Luke 17: 20-37</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2024:36-51');">
        St. Matthew 24: 36-51</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2034');">
        Psalms 34</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2042:1-%2028');">
        Genesis 42: 1- 28</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%201:10%20-%2020');">
        Isaiah 1 : 10 - 20</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%203:21%20-%2026');">
        Acts 3: 21 - 26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%202:10-16');">
        I Corinthians 2: 10-16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%2013:28%20-37');">
        St. Mark 13: 28 -37</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">September-24: Second Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="122" name="122">
        <div class="MsgTit" id="122Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2014:34-%2036,15:1%20-%2011');">
        St. Matthew 14: 34- 36, 15: 1 - 11</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2015:12%20-%2020');">
        St. Matthew 15: 12 - 20</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2042:9-17');">
        Genesis 42: 9-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Kings%203:5-9');">
        I Kings 3: 5-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Great%20Wisdom%206:1-9');">
        Great Wisdom 6: 1-9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2048:12-16');">
        Isaiah 48: 12-16</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%205:17%20-%2032');">
        Acts 5: 17 - 32</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%202:14-3:9');">
        I Corinthians 2: 14-3: 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2016:5-12');">
        St. Matthew 16: 5-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">October-1: Third Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="123" name="123">
        <div class="MsgTit" id="123Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2018:23-35');">
        St. Matthew 18: 23-35</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2016:1-13');">
        St. Luke 16: 1-13</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2042:18-%2035');">
        Genesis 42: 18- 35</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Proverbs%201:2%20-%209');">
        Proverbs 1: 2 - 9</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%204%208:12%20-%2016');">
        Isaiah 4 8 : 12 - 16</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%207:2%20-%205');">
        Acts 7: 2 - 5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%208:1%20-%2011');">
        Romans 8: 1 - 11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Mark%202:23%20-%2028');">
        St. Mark 2: 23 - 28</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">October-8: Fourth Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="124" name="124">
        <div class="MsgTit" id="124Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:1-16');">
        St. Mark 10: 1-16</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%209:37-45');">
        St. Luke 9: 37-45</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%2029:35-40');">
        Numbers 29: 35-40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Kings%208:22-30');">
        I Kings 8: 22-30</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2042');">
        Psalms 42</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2021:17%20-%2026');">
        Acts 21: 17 - 26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%201:21-29');">
        I Corinthians 1: 21-29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2016:9%20-18');">
        St. Luke 16: 9 -18</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">October-15: Fifth Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="125" name="125">
        <div class="MsgTit" id="125Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2020:27-40');">
        St. Luke 20: 27-40</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2022:34-44');">
        St. Matthew 22: 34-44</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Leviticus%202:1-3');">
        Leviticus 2: 1-3</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2046');">
        Psalms 46</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2040:27-31');">
        Isaiah 40: 27-31</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2022:22-29');">
        Acts 22: 22-29</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Timothy%206:13-21');">
        I Timothy 6: 13-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2023:1-12');">
        St. Matthew 23: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">October-22: Sixth Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="126" name="126">
        <div class="MsgTit" id="126Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2019:13-26');">
        St. Matthew 19: 13-26</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:17-27');">
        St. Mark 10: 17-27</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2042:29-36');">
        Genesis 42: 29-36</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%2084');">
        Psalms 84</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2043:16-25');">
        Isaiah 43: 16-25</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2021:27%20-40');">
        Acts 21: 27 -40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%205:6-13');">
        I Corinthians 5: 6-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%2018:18-27');">
        St. Luke 18: 18-27</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <a name="now"></a><div class="DayBox">
        <h3 class="SubTit">October-29: Seventh Sunday after the Festival of Holy Cross</h3>
        <div style="page-break-inside: avoid" id="127" name="127">
        <div class="MsgTit" id="127Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2012:13-21');">
        St. Luke 12: 13-21</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2012:48%20-59');">
        St. Luke 12: 48 -59</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2012:43-%2051');">
        Exodus 12: 43- 51</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Job%2023:1-7');">
        Job 23: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2052:1-15');">
        Isaiah 52: 1-15</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:15%20-%2017');">
        I Peter 2: 15 - 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%206:9%20-%2011');">
        I Corinthians 6: 9 - 11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%205:21%20-%2026');">
        St. Matthew 5: 21 - 26</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">November-2: St. Gregorios of Parumala (Parumala Thirumeni)</h3>
        <div style="page-break-inside: avoid" id="150" name="150">
        <div class="MsgTit" id="150Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2015:12%20-%2021,16:1%20-3');">
        St. John 15: 12 - 21, 16: 1 -3</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:28%20-%2031');">
        St. Mark 10: 28 - 31</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2019:15%20-%2023');">
        Genesis 19: 15 - 23</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Job%201:12%20-%2022');">
        Job 1: 12 - 22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2038:1%20-%208');">
        Isaiah 38: 1 - 8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2027:9%20-%202%206');">
        Acts 27 : 9 - 2 6</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%2010:33-%2011:7');">
        Hebrews 10: 33- 11: 7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2010:34%20-%2042');">
        St. Matthew 10: 34 - 42</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">November-5: Koodhosh Eetho (Sanctification) Sunday</h3>
        <div style="page-break-inside: avoid" id="001" name="001">
        <div class="MsgTit" id="001Txt">Description<div class="MsgTxt">
        
        The Sunday that comes on or after October 30th is called
        
        Koodhosh Eetho (Sanctification of Church) Sunday. It
        
        is the beginning of the church calendar.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%208:27%20-33');">
        St. Mark 8: 27 -33</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2021:15-%2022');">
        St. John 21: 15- 22</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2033:7-11');">
        Exodus 33: 7-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2040:17-38');">
        Exodus 40 : 17-38</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%206:1%20-%208');">
        Isaiah 6: 1 - 8</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:1-12');">
        I Peter 2:1-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%203:16-17,6:15-20');">
        I Corinthians 3: 16-17, 6: 15-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%2016:13-23');">
        St. Matthew 16:13-23</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">November-12: Hoodhosh Eetho (Dedication) Sunday</h3>
        <div style="page-break-inside: avoid" id="002" name="002">
        <div class="MsgTit" id="002Txt">Description<div class="MsgTxt">
        
        The Sunday after Koodhosh Eetho is called
        
        Hoodhosh Eetho (Dedication of Church) Sunday.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2019:47-%2020:8');">
        St. Luke 19: 47- 20: 8</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2012:41-44');">
        St. Mark 12: 41-44</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%2033:7-11');">
        Exodus 33: 7-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Kings%208:22%20-%2040');">
        I Kings 8 :22 - 40</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2055:1-%2013');">
        Isaiah 55: 1- 13</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%207:44-53');">
        Acts 7: 44-53</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%209:1-14');">
        Hebrews 9: 1-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('John%2010:22%20-%2038');">
        St. John 10: 22 - 38</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">November-19: Annunciation to Zechariah (Parents' day)</h3>
        <div style="page-break-inside: avoid" id="003" name="003">
        <div class="MsgTit" id="003Txt">Description<div class="MsgTxt">
        
        This Sunday is commemorated as the day when John the Baptist's
        
        birth was announced to Zechariah by Angel Gabriel.
        </div>Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2019:3-12');">
        St. Matthew 19: 3-12</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2010:38%20-%2012');">
        St. Luke 10: 38 - 12</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2017:15-22');">
        Genesis 17: 15-22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Numbers%208:13-20');">
        Numbers 8: 13-20</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Samuel%201:9-17');">
        I Samuel 1: 9-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2041:8-15');">
        Isaiah 41: 8-15</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('II%20Peter%201:1-15');">
        II Peter 1: 1-15</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%205:21%20-6:4');">
        Ephesians 5: 21 -6: 4</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%201:5%20-%2025');">
        St. Luke 1: 5 - 25</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">November-26: Annunciation to St. Mary, Mother of God.</h3>
        <div style="page-break-inside: avoid" id="004" name="004">
        <div class="MsgTit" id="004Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%208:16-%2021');">
        St. Luke 8: 16- 21</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%203:31-35');">
        St. Mark 3: 31-35</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2028:10%20-22');">
        Genesis 28: 10 -22</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Judges%2013:2-14');">
        Judges 13: 2-14</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%202:10-13,4:1-7,8:3');">
        Zechariah 2: 10-13, 4: 1-7, 8: 3</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2063:15-64:5');">
        Isaiah 63: 15-64: 5</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%203:2-17');">
        I John 3: 2-17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%204:1-7');">
        Galatians 4: 1-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%201:26-38');">
        St. Luke 1: 26-38</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-3: St. Mary's visit to Elizabeth (Ladies' day)</h3>
        <div style="page-break-inside: avoid" id="005" name="005">
        <div class="MsgTit" id="005Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%208:1-%203');">
        St. Luke 8: 1- 3</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%2010:38-42');">
        St. Luke 10: 38-42</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%201:24%20-%2031,24:15-38');">
        Genesis 1: 24 - 31, 24: 15-38</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Judges%204:4%20-16');">
        Judges 4: 4 -16</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('II%20Kings%205:1%20-5');">
        II Kings 5: 1 -5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ester%204:1-%2017');">
        Ester 4: 1- 17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Proverbs%2014:1-19');">
        Proverbs 14: 1-19</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%203:1%20-7');">
        I Peter 3: 1 -7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Timothy%202:9%20-%2015,3:11-13');">
        I Timothy 2: 9 - 15 , 3:11-13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%201:39-56');">
        St. Luke 1: 39-56</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-10: Birth of John the Baptist (Children's day)</h3>
        <div style="page-break-inside: avoid" id="006" name="006">
        <div class="MsgTit" id="006Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Mark%2010:13-16');">
        St. Mark 10 : 13-16</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2011:11-19');">
        St. Matthew 11: 11-19</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2021:1-21');">
        Genesis 21: 1-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Exodus%202:1-10');">
        Exodus 2: 1-10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Psalms%20127:1%20-5');">
        Psalms 127 : 1 -5</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%2062:1-12');">
        Isaiah 62: 1-12</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%203:1-3');">
        I John 3: 1-3</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Ephesians%206:1-4;Colossians%203:20-21');">
        Ephesians 6: 1-4; Colossians 3: 20-21</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%201:57-80');">
        St. Luke 1: 57-80</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-17: Revelation to St. Joseph</h3>
        <div style="page-break-inside: avoid" id="007" name="007">
        <div class="MsgTit" id="007Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:30-46');">
        St. John 6: 30-46</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%206:30-46');">
        St. John 6: 30-46</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2013:1-11');">
        Genesis 13: 1-11</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%201:2%20-%209');">
        Isaiah 1: 2 - 9</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20Peter%202:11%20-17');">
        I Peter 2: 11 -17</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%201:11-24');">
        Galatians 1: 11-24</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%201:18-25');">
        St. Matthew 1: 18-25</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-24: Sunday before Christmas</h3>
        <div style="page-break-inside: avoid" id="008" name="008">
        <div class="MsgTit" id="008Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%201:1-%2018');">
        St. Matthew 1: 1- 18</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%201:1-18');">
        St. John 1: 1-18</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2046:8-27');">
        Genesis 46: 8-27</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Kings%209:3-7');">
        I Kings 9: 3-7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%207:14-15,11:1%20-9');">
        Isaiah 7: 14-15, 11: 1 -9</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%203:16-26');">
        Acts 3: 16-26</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Romans%204:13-25');">
        Romans 4: 13-25</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%203:23-38');">
        St. Luke 3: 23-38</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-25: Yeldho/Incarnation of our Lord (Christmas)</h3>
        <div style="page-break-inside: avoid" id="009" name="009">
        <div class="MsgTit" id="009Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%201:1-18');">
        St. John 1: 1-18</a></div></div>By the fire-pit<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Hebrews%201:1-12');">
        Hebrews 1: 1-12</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Luke%202:1-14');">
        St. Luke 2: 1-14</a></div>
        </div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Luke%202:15-20');">
        St. Luke 2: 15-20</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2021:1-8');">
        Genesis 21: 1-8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Micah%205:5%201-%204');">
        Micah 5:5 1- 4</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%209:1-7');">
        Isaiah 9: 1-7</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('I%20John%201:1-%2010');">
        I John 1: 1- 10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Galatians%203:23-%204:7');">
        Galatians 3: 23- 4 :7</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%202:1-12');">
        St. Matthew 2: 1-12</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        <div class="DayBox">
        <h3 class="SubTit">December-31: First Sunday after Christmas (The Holy Family flee to Egypt)</h3>
        <div style="page-break-inside: avoid" id="012" name="012">
        <div class="MsgTit" id="012Txt">Evening<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('Matthew%2010:16-23');">
        St. Matthew 10 : 16-23</a></div></div>Morning<div class="MsgTxt"><div class="MsgTxt"><a href="javascript:openBible('John%2015:18-21');">
        St. John 15: 18-21</a></div></div>Before Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Genesis%2037:13%20-%2028');">
        Genesis 37: 13 - 28</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Zechariah%208:3-8');">
        Zechariah 8: 3-8</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Isaiah%205:20-30');">
        Isaiah 5: 20-30</a></div>
        </div>Holy Qurbana<div class="MsgTxt">
        <div class="MsgTxt"><a href="javascript:openBible('Acts%2016:6%20-10');">
        Acts 16: 6 -10</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('I%20Corinthians%2010:1-%2013');">
        I Corinthians 10 : 1- 13</a></div>
        <div class="MsgTxt"><a href="javascript:openBible('Matthew%202:9%20-%2015,19-%2023');">
        St. Matthew 2: 9 - 15, 19- 23</a></div>
        </div>
        </div>
        <br>
        </div>
        </div>
        </td></tr>
        <tr><td>
        <font color="blue" size="-3">Prepared by:</font><a href="https://www.stmarysnova.org/"><font color="green" size="1pt" style="font-weight:bold; text-decoration: underline">
               Web Team,
               St. Mary's Indian Orthodox Church,
               Northern Virginia
               </font></a>
        </td></tr>
        </tbody></table>
</section>
