import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss']
})
export class TendersComponent implements OnInit {

    constructor( private titleService: Title ) {
        this.titleService.setTitle('St George Orthodox Syrian Cathedral - Public Notices');
    }

    ngOnInit() {
    }

}
