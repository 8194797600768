import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators  } from '@angular/forms';
import { ActivitiesService } from '../../../services/activities.service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginDetailsform: FormGroup;
    spinnerTrue: boolean = false;
    showAlert: boolean = false;
    alertMessage: string = "";
    alertStatus: string = "";
    constructor( private titleService: Title, private loginDetailsBuilder: FormBuilder, private activities: ActivitiesService, private router: Router ) {
        this.titleService.setTitle('St George Orthodox Syrian Cathedral - Login Page');
    }

    ngOnInit() {
      this.createForm();
    }

    createForm() {
      this.loginDetailsform = this.loginDetailsBuilder.group({
        userName: ['', [Validators.required, Validators.minLength(3)]],
        password: ['', [Validators.required, Validators.minLength(2)]],
      });
    }

    login() {
      if (this.loginDetailsform.invalid) {
        return;
      }  
      this.spinnerTrue = true;
      this.activities.postLoginRequest(this.loginDetailsform.value)
        .pipe(first())
        .subscribe(
          data => {
            this.spinnerTrue = false;
            this.router.navigate(['/login/signed-in']);
          },
          error => {
            this.spinnerTrue = false;
            this.showAlert = true;
            this.alertStatus = "error"
            this.alertMessage = "Login Failed. Please check credentials"
            setTimeout(() => {
              this.showAlert = false;
              this.alertMessage = ""
            }, 5000);
          });
      
    }

}
