import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-evangelion-chapters',
  templateUrl: './evangelion-chapters.component.html',
  styleUrls: ['./evangelion-chapters.component.scss']
})
export class EvangelionChaptersComponent implements OnInit {

  constructor(private titleService: Title) { 
    this.titleService.setTitle('St George Orthodox Syrian Cathedral - Evangelion Chapters');
  }

  ngOnInit(): void {
  }

}
