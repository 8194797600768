<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-custom variant4 d-print-none">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Administration</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Managing Committee</h2>
            <div class="bar"></div>
        </div>
        <div class="col-lg-6 col-md-6">
        <h3>Clergy</h3>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/karukayil.jpeg" alt="team">

                    <div class="team-content">
                        <h3>Very Rev Dr Joseph Samuel Karukayil Cor-Episcopa</h3>
                        <span>Vicar</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+919446963444">9447450612</a>  
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/geevarghese-joshua.jpeg" alt="team">

                    <div class="team-content">
                        <h3>Rev Fr Geevarghese Joshua</h3>
                        <span>Asst Vicar</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+919846890140">9846890140</a>  

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/sam-thomas.jpg" alt="team">

                    <div class="team-content">
                        <h3>Rev Fr Sam Thomas</h3>
                        <span>Asst Vicar</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+919497362375">9497362375</a>  
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <h3>Office Bearers</h3>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/gt.jpeg" alt="team">

                    <div class="team-content">
                        <h3>Mr George Thomas</h3>
                        <span>Trustee</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+919446957993">9446957993</a>  

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/korah.jpg" alt="team"> -->
                    <img src="assets/church-img/managing-committee/isaac.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Issac K Thayyil</h3>
                        <span>Secretary</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+918547083346">9447303885</a>  

                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <h3>Ward Members</h3>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/jogi.jpg" alt="team"> -->
                    <img src="assets/church-img/managing-committee/ushag.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mrs Usha George</h3>
                        <span>Palayam Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919809034879">9809034879</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/tkjoseph.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Saji Cherian</h3>
                        <span>Kunnukuzhy Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919446384941">9446384941</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/gracy.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Yoshua Chacko</h3>
                        <span>Pattom, Gowr Pattom Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919746335320">9746335320</a>   -->
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/george.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Georgekutty</h3>
                        <span>Nanthancode Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919446552929">9446552929</a>   -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/alexv.jpeg" alt="team">
                    <!-- <img src="assets/img/team-img1.jpg" alt="team"> -->

                    <div class="team-content">
                        <h3>Mr Alex Varghese</h3>
                        <span>Kaudiar-Kuravankonam Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+918547456059">8547456059</a>   -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/biju.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Biju Thomas</h3>
                        <span>Vazhuthacaud-Thycaud Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919447340682">9447340682</a>   -->

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/kgthomas.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Raju kurian</h3>
                        <span>Jagathy-Poojappura Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919446093462">9446093462</a>   -->

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/abraham.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mrs Reny Mammen</h3>
                        <span>Vanchiyoor-Pettah-Sanghumugham Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919895829166">9895829166</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/jegi.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr P D Mathew</h3>
                        <span>Puthenchantha-Chala-Manacaud Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+918547083346">8547083346</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/isaac.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr T U Varghese</h3>
                        <span>Kumarapuram Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+918547083346">9447303885</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/john.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Jose Mathew</h3>
                        <span>Ulloor-Sreekariyam Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+918606638668">8606638668</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <!-- <img src="assets/church-img/managing-committee/joshua.jpg" alt="team"> -->
                    <img src="assets/img/team-img1.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr Raju Cherian</h3>
                        <span>Kesavadasapuram Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+919447552334">9447552334</a>   -->

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/rachel.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mrs Rachel Mathew</h3>
                        <span>Ambalamukku-Peroorkada Ward</span>
                        <!-- <i class="icofont-phone mt-3"></i><a href="tel:+918139858505">8139858505 </a>   -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6">
                <div class="single-team-member">
                    <img src="assets/church-img/managing-committee/tkjoseph.jpg" alt="team">

                    <div class="team-content">
                        <h3>Mr T K Joseph</h3>
                        <span>Ex Officio</span>
                        <i class="icofont-phone mt-3"></i><a href="tel:+919447429393">9447429393</a>

                    </div>
                </div>
            </div> -->

        </div>
    </div>
</section>
<!-- End Team Area -->