<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-variant1">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>About Parish</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
    

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Parish History</h2>
                        <div class="bar"></div>
                        <p>St George's Orthodox Syrian Cathedral was consecrated by Saint Geevarghese Mar Gregorios, popularly known as Parumala Thirumeni in the presence of Mar Joseph Dionysius who was the Malankara Metropolitan.</p>
                    </div>
                    <p>Church was evelated as Cathedral on 23-6-1991 By H.H Baselius Marthoma Mathews II, Catholicos of the East & Malankara Metropolitan in the presence of H.G Geevarghese Mar Dioscorus, Metropolitan of Thiruvananthapuram Diocese</p>
                </div>
                <p>(To be updated)</p>

            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/church-plate.jpeg" alt="image">
            </div>
        </div>
    </div>

</section>
<!-- End About Area -->



