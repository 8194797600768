import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(private http: HttpClient) { }

  postLoginRequest(loginValues: any) {
    return this.http.post<any>(`https://5wncog1xt9.execute-api.us-east-1.amazonaws.com/dev/login`, loginValues)
        .pipe(map(data => {
            return data;
        }));
  }

  postPrayerRequest(slotValues: any) {
    return this.http.post<any>(`https://5wncog1xt9.execute-api.us-east-1.amazonaws.com/dev/catagorisedprayers`, slotValues)
        .pipe(map(data => {
            return data;
        }));
  }

  getPrayerRequest() {
    return this.http.get<any>(`https://5wncog1xt9.execute-api.us-east-1.amazonaws.com/dev/getcatagorisedprayers`)
        .pipe(map(data => {
            return data;
        }));
  }

}
