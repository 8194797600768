<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Patron Saints</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>St. Geevarghese Mar Gregorios Parumala</h2>
                        <div class="bar"></div>
                        <p>Saint Gregorios of Parumala is popularly known as ‘Parumala Thirumeni’. Metropolitan Geevarghese Mar Gregorios of the Malankara Orthodox Church who passed away on November 2nd 1902, became the first declared saint from Malankara (Kerala, India) naturally to be called, ‘Parumala Thirumeni’. He shines in the cloud of witnesses as a bright luminary giving rays of hope to millions in their suffering and struggles.</p>
                    </div>
                    <p>BIRTH AND PARENTAGE</p>
                    <p>Mar Gregorios was born on 15th June 1848 (M.E. Mithunam 3, 1023) to Kochu Mathai and Mariam of Pallathetta family in the Chathuruthy house at Mulamthuruthy. He was called by the name ‘Kochaippora’ and was given the baptismal name ‘Geevarghese’. Kochaippora had two brothers and two sisters; Kurian, Eli, Mariam and Varkey. Kochaippora was the youngest and was therefore the most beloved to everyone. Unfortunately, his mother passed away when he was only two years old. His eldest sister Mariam became to him all that a mother was meant. Mariam was married at that time and had a child of Kochaippora’s age.</p>
                    <p>READER-DEACON AND FURTHER EDUCATION</p>
                    <p>He was ordained as a reader-deacon (Korooyo) on 14th Sept, 1858 at the age of ten by Palakkunnath Mathews Mar Athanasios at Karingachira Church. Koroyo Geevarghese continued his training under Geevarghese Malpan until the latter died due to small pox. Although Deacon Geevarghese was also infected with small pox, he miraculously survived it. Afterwards Deacon Geevarghese moved to Pampakuda to continued his studies under Konat Geevarghese Malpan. In the mean time Deacon became associated with the visiting Syrian Bishop Yuyakim Mar Coorilos. Mar Coorilos had great admiration for the deacon and was pleased to ordain him as full deacon, priest and cor-episcopa within few months in 1865.</p>
                    <p>VETTICKAL DAYARA</p>
                    <p>The new priest’s short stay at Mulanthuruthy Marthommen Church gave him an inner conviction that he should lead a hermit’s life in a quieter place. Therefore he shifted his residence to Vettickal Dayara. At Vettickal Dayara, Corepiscopa Geevarghese started a strenuous life of prayer and fasting. Having heard about the vigorous asceticism practised by corepiscopa Geevarghese, the then Malankara Metropolitan Pulikkottil Joseph Mar Dionysius made him a ‘Ramban’ (Monk Priest) in 1872.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/Parumala-Thirumeni.jpg" alt="image">
                <p>Picture of Tirumeni taken in the premises of St George Cathedral by Mr Zacharias D'cruz, Royal photographer of Travencore for Travancore State Manual</p>
            </div>
        </div>
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <p>PATRIARCHAL VISIT AND THE SYNOD OF MULAMTHURUTHY</p>
                <p>In 1875, the Antioch Patriarch His Holiness Peter III visited Malankara. The Patriarch chose Ramban  Geevarghese as his Secretary and translator during the entire visit. Along with the Patriarch , the Ramban visited many churches. Ramban Geevarghese also assisted the Patriarch in the consecration of the Holy Mooron and in the historic synod of Mulanthuruthy in 1876.</p>
                <p>CONSECRATION AS METROPOLITAN</p>
                <p>Being pleased with the Ramban Geevarghese, the Patriarch decided to consecrate him as Metropolitan. On December 10, 1876 the Patriarch consecrated six priests as bishops including Ramban Geevarghese at St. Thomas Church, N Paravur. He was given the new name Geevarghese Mar Gregorios and was given the charge of Niranam Diocese. The other bishops and their Diocese were:
                    Murimattath Mar Ivanios (Kandanad)
                    Kadavil Mar Athanasios (Kottayam)
                    Ambattu Mar Coorilos (Ankamaly)
                    Karottuveetil Simon Mar Dionysius (Cochin)
                    Konat Mar Julius (Thumpamon)
                    St.Thomas Church, N Paravur
                    Mar Gregorios was only 28 years when he was made a bishop. Since he was the youngest among all the bishops, he was dearly called by all as ‘Kochu Thirumeni’. The first thing the new bishops undertook was a special fasting-vigil for forty days at Vettickal Dayara under the leadership of ‘Kochu Thirumeni’. This fasting was both symbolic and effective in the pursuit of new life in an old church.
                    Mar Gregorios took charge of the Niranam Diocese and started staying at Parumala. There was at Parumala, at that time, a land donated by Arikupurath Koruth Mathen to the church and in this plot a small building was erected by the Malankara Metropolitan Pulikkottil Joseph Mar Dionysius. This building was known as ‘Azhippura’. Mar Gregorios lived there along with few other deacons who came for priestly training. They worshipped in a thatched chapel during that time.</p>
                <p>THREEFOLD ACTIVITY</p>
                <p>Mar Gregorios engaged in a threefold activity of tireless service for the church: Diocesan administration, Ministerial formation of deacons, Missionary witness of the church through inner spiritual and theological consolidation, along with evangelical reaching out.
                    In addition to these, Mar Gregorios undertook the task of building a church and seminary at Parumala. The diocesan administration, in the mean time, was extended to two more dioceses, Thumpamon and Quilon. The newly constructed church was consecrated in 1895. Mar Gregorios was the co-celebrant for the consecration of two ex-Roman Catholic priests as bishops: Fr.Alvaris as Alvaris Mar Kulius for Bombay-Mangalore Diocese; Fr.Rene Vilatti as Rene Vilatti Mar Timotheos for America.</p>

                <p>HOLY LAND – PILGRIMAGE</p>
                <p>Mar Gregorios made the Holy Land Pilgrimage in 1895 as the fulfillment of a long cherished dream. On his return he published a travelogue under the title ‘Oorslem yathra vivaranam’ (a narrative of the Jerusalem visit). This book, published in 1895 is to be considered as the earliest printed travelogue in Malayalam. This book had its centenary edition in 1996 and translation into English in 2000.</p>
                <p>A VISION AND MISSION FOR THE ENTIRE CHURCH</p>
                <p>Mar Gregorios believed that the church should engage in educational activity especially to facilitate primary education and English teaching without discriminating gender or religion. Accordingly he started schools at Kunnamkulam, Mulamthuruthy, Niranam, Thumpamon, Thiruvalla etc. The missionary task of the Church was also evinced by his outreach programme to the socially down trodden communities at Chennithala, Kalikunnu, Mallappally, Puthupally, Kallumkathara etc. He also organized evangelical awakening programme for non-Christians at various places like Aluva, under the leadership of the Seminary students.
                    A major task of Mar Gregorios was to motivate the clergy for effective ministry. With this aim, he formed the Malankara Syrian Clergy Association and took many progressive decisions and made many suggestions for the effective functioning of the priestly ministry.</p>
                <p>DISCIPLES OF THIRUMENI</p>
                    <p>Among the many disciples of Mar Gregorios, three deserve special notice:
                        1. Vattasseril Rev.Fr.Geevarghese (later, Malankara Metropolitan Geevarghese Mar Dionysius)
                        2. Kuttikattu Rev.Fr.Paulose (later, Paulose Mar Athanasios of Aluva)
                        3. Kallasseril Rev.Fr,Geevarghese (Punnoose) (later, Catholicos Baselios Geevarghese II)</p>
                <p>DEPARTURE FROM THE WORLD</p>
                <p>Mar Gregorios was already a piles-patient. It became chronic in 1902. Treatments proved futile and slowly His Grace became physically weaker and weaker. At last the blessed soul left the earthly abode on 2nd November 1902. The funeral was conducted at Parumala on Tuesday the 3rd of November 1902 in the presence of thousands of people and hundreds of priests. The many testimonies to the saintly intercession of Mar Gregorios made Parumala Church and the tomb a centre of pilgrimage. In 1947 Mar Gregorios of blessed memory was declared a saint by the then Catholicos of the church, His Holiness Baselius Geevarghese II.</p>        
                
                </div>
        </div>
    </div>

</section>
<!-- End About Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>St. Geevarghese Mar Dionysius Vattasseril</h2>
                        <div class="bar"></div>
                        <p>St. Geevarghese Mar Dionysius Vattasseril, Malankara Metropolitan, was a bright light for the Malankara Orthodox Syrian Church that illumined during her dark and tumultuous times and possessed the vision to bring the Church triumphantly from the bonds of foreign oppression.  Thirumeni dedicated his entire life to secure the freedom and welfare of the Holy Church.  His Grace faced many troubles and obstacles as well as received constant physical and verbal abuse via threats and physical attacks as he courageously led the Church to her independence.</p>
                    </div>
                    <p>He confronted the dangers and obstacles directly responding with vigor, strength and remarkable conviction and confidence in God’s justice and plan, which was a product of his continual fasting and prayer. God protected Thirumeni throughout his life whether in Kerala or abroad as he sought the freedom of the Church from foreign powers.  His great triumph lay in the ability to unite the entire Church, both the priests and laymen to follow his lead.  He was incredibly gifted in many fields, a multifarious genius.  He was a spiritual leader, a theological educator, scholar of languages, literature and traditions.  He was a dignified, valorous and noble personality with a remarkable commanding power.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/vattaseril-tirumeni.jpeg" alt="image">
            </div>
        </div>
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <p></p>
                <p>Early Life</p>
                <p>St. Dionysius was born to his parents, Joseph Vattasseril of Mallappally and Eliamma Kolathu Kalathil of Kurichy on 31st October 1858.  Following his elementary education at C. M. S. Middle School in Mallappally he completed his high school education from C. M. S. High School, Kottayam. In 1876, while still a high school student, he was ordained as a sub deacon by H. H. Moran Mar Pathrose Patriarch.</p>
                <p>Life in the Church</p>
                <p>Dn. Geevarghese studied at the Orthodox Theological Seminary (Old Seminary or Pazhaya Seminary), Kottayam for four years thereby undergoing his theological training.  Dn. Geevarghese soon became a great Syriac scholar under the careful tutelage of St. Gregorios of Parumala, who taught him at Seminary.  In 1879 Dn. Geevarghese was ordained as a full deacon and in 1880 he was ordained as a priest by St. Gregorios.  By 1880, Fr. Geevarghese had become an authority in the Syriac, Church History, Faith and Doctrine, the Church Fathers, and Theology.  In recognition of his incredible expertise in Syriac and theology he was designated as Malankara Malpan.  He spent his spare time reading, studying, and thinking which translated to his many renowned writings such as “Doctrines of the Church”.  He also used his scholarship to edit and publish the order of Church worship to be used by the ordinary faithful for meaningful participation in worship.  He was appointed as Principal of M. D. Seminary, Kottayam as he was both a great scholar and administrator.  In 1903, he was blessed as a Ramban (monk).  He also served as the Manager of Parumala Seminary.  In 1908 he was consecrated as H. G. Geevarghese Mar Dionysius Metropolitan and served as the Assistant Malankara Metropolitan.  The next year he became the Malankara Metropolitan and served and led the Church in that capacity until his departure from this life in 1934 when he and the Church triumphed in establishing the official constitution of the Malankara Orthodox Syrian Church.</p>
                <p>Legacy</p>
                <p>H. H. Moran Mar Baselios Geevarghese II Catholicos of blessed memory remarked in the speech at the burial of Vattasseril Thirumeni, “When we look at the highest solemn position held by Vattasseril Thirumeni and his deep and firm faith in God, he seemed similar to Moses who led the sons of Abraham from the captive land of Egypt to the promised land of freedom and happiness.  There is no doubt about it.  Moses had spent his entire life for the freedom of his people but he could not enter the Promised Land.  He was only able to see the Promised Land from a distance.  Likewise the Moses of the Malankara Church has also watched the freedom of his Church from a distance”.  Vattasseril Thirumeni was a good orator who was well aware of the importance of the vitality and moral persuasiveness of words when delivering the speeches to the faithful.  Spiritually, he was transformed by Christ and bore no scars from sin.  His humility and withdrawal from the praise of this world kept many from seeing the incredibly pious and faithful life that Thirumeni lived.  In addition to not publicizing his own spiritual advancement he also avoided spiritual hypocrisy and arrogance throughout his life.  Prayers and fasting were the pillars that were Vattasseril Thirumeni’s spiritual foundation.  He faced all the challenges with the power he had gained through his valued spiritual life.  In addition to the liturgical hours of prayer, Thirumeni spent much time in private prayers and silent meditations behind closed doors and away from the attention of people.  In spite of his busy schedule, he was also able to focus on three to four lessons from the Holy Bible everyday.  Despite Vattasseril Thirumeni’s literal application of Christ’s instruction to pray in private and not for others to see, many recognized that His Grace was a living saint amongst them.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->
