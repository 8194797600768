<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>Our Bishops</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h3>Catholicos</h3>
                        <h2>H.H. Baselios MarThoma Mathews III (2021-Present)</h2>
                        <div class="bar"></div>
                        <p>His Holiness Baselios Marthoma Mathews III was enthroned as the Catholicos of the East & Malankara Metropolitan (the Supreme Head of the Malankara Orthodox Syrian Church of India) on Friday, 15th October 2021. His Holiness is the 92nd Primate on the Apostolic Throne of St. Thomas.</p>
                    </div>
                    <p>His Holiness was born on 12 February 1949 to Mr Cherian Anthrayos of Mattathil family, being a member of St Peters Church, Vazhoor. After his school education, he joined Kerala University and passed his BSc Chemistry. After his BSc, he joined Orthodox Seminary, Kottayam, and had his GST degree. His Holiness took his BD degree from the Serampore University and did his higher studies in Theology at  Theological Academy, Leningrad, Russia. Thereupon he joined Oriental Institute, Rome, and took his MTh and PhD from there. His Holiness was ordained a deacon in 1976 and a priest in 1978 by HH Baselios Mathews I. His Holiness was escalated to the post of an Episcopa on 30 April 1991 at a function at Parumala, and metropolitan in 1993. He is a well-known teacher and a faculty member of the Orthodox Seminary, Kottayam. A philanthropist, he works relentlessly for the uplift of the poor, especially women. He has started many ventures to help give employment opportunities to women from the economically backward classes. His Holiness was  also served the Holy Episcopal Synod as its secretary.</p>
                    <p>His Holiness has authored a few devotional and contemplative books in Malayalam.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/catholicos.jpeg" alt="image">
            </div>
        </div>
    </div>

</section>
<!-- End About Area -->

<!-- Start Team Area -->
<section class="team-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h3>Diocesian Metropolitan</h3>
                        <h2>H.G. Dr. Gabriel Mar Gregorios Metropoltan</h2>
                        <div class="bar"></div>
                        <p>His Grace  was born on 10 February 1948 to Mr CM John and Mrs Aleyamma John, Vadakethazhethil, Kanjickal. He did schooling at St Stephen’s High School, Pathanapuram. He persued his BA from Kerala University in English Literature. Subsequent to that, he joined the Serampore University and took his BD degree in 1974. He did his MTh from the Catholic University, Paris.</p>
                    </div>
                    <p> His Grace persued successfully a three-year Diploma Course in Biblical Hebrew, a two-year certificate course in Aramic and advanced course in Biblical Greek at the Catholic University, Paris.  His Grace also did Doctoral Research at the Lutharance School of Theology at Chicago on the topic “The Glory of God in St Paul” and took his PhD in New Testament from the Serampore University. He further did his Doctoral Research at the University of Cambridge. His Grace was ordained a deacon on 4 November 1969 by HH Mar Baselios Augen I.  HH Baselious Mathews I ordained Dn Gabriel as priest on 8 January 1974. He distinctly served as vicar of many parishes such as Chenganachery, Jaipur, Dehuroad, Kuzhimattom, Mundakayam, Kanakappalam, Odanavattom Nellikunnam, and Pooyapally. He was a Professor at Orthodox Seminary, Kottayam.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/gabrel-tirumeni.jpeg" alt="image">
            </div>
        </div>
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-12 col-md-12">
                <p>From his childhood Paul showed keen interest in spiritual matters. His parish church, St. Marys Orthodox church was the nerve centre of his spiritual growth. At the age of 13, he was selected by his parish priest to be vast in the ‘washing of feat’ service celebrated by H G Paulose Mar Severios Metropolitan. This marked a turning point in the life of young Paul when the metropolitan invited him to the priesthood. The influence of his parents especially that of his mother who hails from the famous Pulikkottil family played a great role in moulding his early life. Even from his childhood, he use to learn Syriac chants and prayers and was very keen in attending the church service. Young Paul joined the Orthodox Theological Seminary from where he secured GST and took BD from the Serampore University.</p>
                <p>He was professed as a Monk on 7 August 2004 by HH Baselios Mathews II.  The Malankara Syrian Christian Association met on 10 June 2004 at the Parumala Seminary, elected Fr Gabriel as a candidate to be ordained to the Episcopal Order and was ordained on 5 March 2005 by HH Baselios Mathews II. At present, His Grace is looking after the Trivandrum Diocese, which is very critical to the church in many ways.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->
