import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-catholicos',
  templateUrl: './catholicos.component.html',
  styleUrls: ['./catholicos.component.scss']
})
export class CatholicosComponent implements OnInit {

  constructor( private titleService: Title ) { 
    this.titleService.setTitle("St George's Orthodox Syrian Cathedral - Catholicos");
  }

  ngOnInit(): void {
  }

}
