<!-- Start Navbar Area -->
<header id="header">
    <div class="crake-nav {{location == '/digital-marketing' ? 'marketing-navbar' : ''}}">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/church-img/round18.png" alt="logo" style="max-width:40%;">
                    <!-- <span><h3 style="color:white"><b>SGOSC</b></h3></span> -->
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav nav ml-auto">
                        <li class="nav-item dropdown">
                            <a routerLink="/"  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" (click) ="hideMenu()">Home</a>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Cathedral</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">About Parish</a></li>

                                <li class="nav-item"><a routerLink="/administration" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Administration</a></li>

                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Church</a>

                            <ul class="dropdown_menu dropdown-menu">

                                <li class="nav-item"><a routerLink="/our-bishops" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Our Bishops</a></li>

                                <li class="nav-item"><a routerLink="/patron-saints" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Patron Saints</a></li>


                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Online Services</a>

                            <ul class="dropdown_menu dropdown-menu">
                                    
                                <!-- <li class="nav-item"><a routerLink="/directoryverification" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Directory Verification</a></li> -->
                                
                                <li class="nav-item"><a routerLink="/live-stream" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Live Stream</a></li>

                                <li class="nav-item"><a routerLink="/christmas-eve" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Christmas Eve</a></li>
                                
                                <li class="nav-item"><a routerLink="/prayer-request" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Prayer Requests</a></li>

                                <li class="nav-item"><a routerLink="/online-payment" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Online Payment</a></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Resources</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/evangelion-chapters" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Scripture Readings</a></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown">Notices</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/publicnotices" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">Public Notices</a></li>
                            </ul>
                        </li>

                        
                        <!-- <li><a routerLink="/prayer-request"  class="nav-link" routerLinkActive="active" data-toggle="dropdown" (click) ="hideMenu()">Prayer Requests</a></li> -->

                        <!-- <li><a routerLink="/online-payment"  class="nav-link" routerLinkActive="active" data-toggle="dropdown" (click) ="hideMenu()">Online Payment</a></li> -->

                        <!-- <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown" (click) ="hideMenu()">Pages</a>

                            <ul class="dropdown_menu dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-us" routerLinkActive="active" class="nav-link" (click) ="hideMenu()">About Us</a></li>

                                <li class="nav-item"><a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a></li>

                                <li class="nav-item"><a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a></li>

                                <li class="nav-item"><a routerLink="/gallery" routerLinkActive="active" class="nav-link">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/projects" routerLinkActive="active" class="nav-link">Portfolio</a></li>

                                <li class="nav-item"><a routerLink="/login" routerLinkActive="active" class="nav-link">Login</a></li>

                                <li class="nav-item"><a routerLink="/signup" routerLinkActive="active" class="nav-link">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" routerLinkActive="active" class="nav-link">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/error" routerLinkActive="active" class="nav-link">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a></li>
                            </ul>
                        </li> -->

                        <!-- <li><a routerLink="/gallery" routerLinkActive="active" (click) ="hideMenu()">Gallery</a></li> -->

                        <li><a routerLink="/contact-us" routerLinkActive="active" (click) ="hideMenu()">Contact Us</a></li>

                        <!-- <li class="nav-item"><a routerLink="contact-us" class="nav-link" routerLinkActive="active" (click) ="hideMenu()">Contact</a></li> -->
                    </ul>
                </div>

                <div class="mr-auto others-option">
                    <ul class="navbar-nav">
                        <li data-toggle="modal" data-target="#myModal2">
                            <div class="side-menu">
                                <span class="bar-1"></span>
                                <span class="bar-2"></span>
                                <span class="bar-3"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- End Navbar Area -->

<app-sidebar></app-sidebar>

<!-- Start Search Box -->
<div id="header-search" class="header-search">
    <button type="button" class="close">×</button>
    <form class="header-search-form">
        <input type="search" value="" placeholder="Type here........" />
        <button type="submit" class="btn btn-primary">Search</button>
    </form>
</div>
<!-- End Search Box -->