import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-live-streaming',
  templateUrl: './live-streaming.component.html',
  styleUrls: ['./live-streaming.component.scss']
})
export class LiveStreamingComponent implements OnInit {
  public embedUrl: string = 'https://www.youtube.com/@jogijacob89/streams';
  public youtubeId: string = 'MdzfXawingg';
  public videoUrl: any;
  qurbanaDate: string = '';
  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.embedUrl);
  }

}
