<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-custom variant2">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Login</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Login Area -->
<section class="login-area ptb-100 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="login-image">
                    <img src="assets/church-img/church-icon.jpeg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h3>Welcome Back!</h3>
                    <p>Please login to your account.</p>

                    <form>
                        <form [formGroup]="loginDetailsform"> 
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Username" formControlName="userName">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Password" formControlName="password">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme">
                                    <label class="form-check-label" for="checkme">Keep me Login</label>
                                </div>
                            </div>

                            <!-- <div class="col-lg-6 text-right">
                                <p class="forgot-password"><a routerLink="/">Forgot Password?</a></p>
                            </div> -->

                            <div class="col-lg-12">
                                <button type="submit" class="btn btn-primary" (click)="login()" *ngIf="!spinnerTrue">Login</button>
                                <button type="submit" class="btn btn-primary" *ngIf="spinnerTrue" disabled>Submit <div class="spinner-border" role="status" style="width:1.5rem; height:1.5rem">
                                    <span class="sr-only">Loading...</span>
                                </div></button>
                                <!-- <br>
                                <span>New User? <a routerLink="/signup">Sign Up!</a></span> -->
                            </div>
                            <div class="col-lg-12 col-md-12" *ngIf="showAlert">
                                <div class="alert alert-danger" role="alert">
                                    {{alertMessage}}
                                </div>
                            </div>
                        </div>
                        </form>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Login Area -->