<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-custom variant3">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Prayer Requests</h2>
                <p>Enter names to be remembered in Holy Qurbana separated by a single comma</p>
                <div class="bar"></div>            
            </div>
        </div>
    </div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="card shadow-lg-custom p-3 mb-5 bg-white rounded">
            <div class="card-body">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <form id="contactForm">
                    <form [formGroup]="prayerRequestform"> 
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="Name">Submitted By</label>
                                <input type="text" class="form-control" formControlName="name" required placeholder="Name of person submitting">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="Name">Phone Number</label>
                                <input type="number" class="form-control" formControlName="phone" required placeholder="Phone Number">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="Name">Blessings</label>
                                <textarea name="message" class="form-control" formControlName="blessings" id="message" cols="30" rows="5" placeholder="Enter Name Example: George, Alan"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="Name">Sick</label>
                                <textarea name="message" class="form-control" formControlName="sick" id="message" cols="30" rows="5" placeholder="Enter Name Example: George, Alan"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label for="Name">Departed</label>
                                <textarea name="message" class="form-control" formControlName="departed" id="message" cols="30" rows="5" placeholder="Enter Name Example: George, Alan"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12" *ngIf="!showAlert">
                            <button type="submit" class="btn btn-primary" (click)="submitRequestPrayer()" *ngIf="!spinnerTrue">Submit </button>
                            <button type="submit" class="btn btn-primary" *ngIf="spinnerTrue" disabled>Submit <div class="spinner-border" role="status" style="width:1.5rem; height:1.5rem">
                                <span class="sr-only">Loading...</span>
                            </div></button>
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="showAlert">
                            <div class="alert" [ngClass]="{'alert-success': alertStatus === 'success', 'alert-error': alertStatus === 'error'}" role="alert">
                                {{alertMessage}}
                            </div>
                        </div>
                    </div>
                    </form>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
</section>
<!-- End Contact Area -->