import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-patron-saints',
  templateUrl: './patron-saints.component.html',
  styleUrls: ['./patron-saints.component.scss']
})
export class PatronSaintsComponent implements OnInit {

  constructor( private titleService: Title ) { 
    this.titleService.setTitle('St George Orthodox Syrian Cathedral - Patron Saints');
  }

  ngOnInit(): void {
  }

}
