<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-variant1">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Public Notices</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="section-title">
                <h2>Tender Notice for footbridge</h2>
                <div class="bar"></div>
                <p>Tender Notice for footbridge can be found in the documents attached</p>
            </div>
        </div>
    </div>
</section>

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <a href="assets/church-img/tender-bridge/Tender_notice_Footbridge_R1.doc"><i class="icofont-email"></i></a>
                    </div>
                    <p><a href="assets/church-img/tender-bridge/Tender_notice_Footbridge_R1.doc">Tender Notice</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <a href="assets/church-img/tender-bridge/Dwg_No_1.pdf" target="_blank"><i class="icofont-email"></i></a>
                    </div>
                    <p><a href="assets/church-img/tender-bridge/Dwg_No_1.pdf" target="_blank">Drawing one</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <a href="assets/church-img/tender-bridge/Dwg_No_2.pdf" target="_blank"><i class="icofont-email"></i></a>
                    </div>
                    <p><a href="assets/church-img/tender-bridge/Dwg_No_2.pdf" target="_blank">Drawing two</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <a href="assets/church-img/tender-bridge/Dwg_No_3.pdf" target="_blank"><i class="icofont-email"></i></a>
                    </div>
                    <p><a href="assets/church-img/tender-bridge/Dwg_No_3.pdf" target="_blank">Drawing three</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <a href="assets/church-img/tender-bridge/Dwg_No_4.pdf" target="_blank"><i class="icofont-email"></i></a>
                    </div>
                    <p><a href="assets/church-img/tender-bridge/Dwg_No_4.pdf" target="_blank">Drawing four</a></p>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area -->

<!-- End Contact Area -->