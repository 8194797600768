import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-orthodox-convention',
  templateUrl: './orthodox-convention.component.html',
  styleUrls: ['./orthodox-convention.component.scss']
})
export class OrthodoxConventionComponent implements OnInit {

  public embedUrl: string = 'https://www.youtube.com/embed/';
  public youtubeId: string = 'PDehpwXf65E';
  public videoUrl: any;
  qurbanaDate: string = 'August 2nd';
  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.embedUrl + this.youtubeId);
  }

}
