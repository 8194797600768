<!-- Start Page Title Area -->
<section class="page-title-banner-custom1 saas-work-process-variant1">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Directory Verification</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="login-area ptb-30 bg-gray">
    <div class="container">
        <div class="row h-100 justify-content-center align-items-center">

            <div class="col-lg-8 col-md-12">
                <div class="login-form">
                    <p>Please enter your register number and click on search button to see your details. If your details are not matching/ if there are missing details/ if photo is not there kindly fill in the response sheet by scrolling down. For further assistance please contact Cathedral office.</p>
                    <form>
                        <form [formGroup]="directoryVerifyform"> 
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="number" class="form-control" placeholder="Enter Register Number" formControlName="regnum">
                                </div>
                            </div>


                            <!-- <div class="col-lg-6 text-right">
                                <p class="forgot-password"><a routerLink="/">Forgot Password?</a></p>
                            </div> -->

                            <div class="col-lg-6">
                                <button [ngStyle]="{'margin-bottom':0,'margin-top':0}" type="submit" class="btn btn-primary" (click)="login()">Search</button>
                              
                                <!-- <br>
                                <span>New User? <a routerLink="/signup">Sign Up!</a></span> -->
                            </div>
                           
                        </div>
                        </form>
                    </form>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" >
                <div class="login-image" [ngStyle]="{'margin-top':'10px'}">
                    <img src="{{dirUrl}}" >
                </div>
            </div>
            <div class="col-lg-12 col-md-12" *ngIf="showResponse" >
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc-EHqd5PZyPN5BB-1DFV9FjdRH_dvkP09NW40PuRKNb4-0fw/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area -->

<!-- End Contact Area -->