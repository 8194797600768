<!-- Start Page Title Area -->
<section class="page-title-banner-custom saas-work-process-variant1">
    <div class="container ">
        <div class="row">
            <div class="col-lg-12">
                <h2>Contact Us</h2>
                <div class="bar"></div>            
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>
</section>
<!-- End Page Title Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-email"></i>
                    </div>
                    <h3>Mail Here</h3>
                    <p><a href="mailto:stgeorgepalayam@gmail.com">stgeorgepalayam@gmail.com</a></p>
                    <p><a href="mailto:trusteestgeorgepalayam@gmail.com">trusteestgeorgepalayam@gmail.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-google-map"></i>
                    </div>
                    <h3>Visit Here</h3>
                    <p>M.G ROAD, SYRIAN CHURCH ROAD, Spencer Junction, Palayam, Thiruvananthapuram, Kerala 695034</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="icofont-phone"></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:+04712322588">Office : 0471 232 2588</a></p>
                    <p><a href="tel:+123456789">Trustee : +91 944 695 7993</a></p>
                    <p><a href="tel:+123456789">Vicar : +91 944 696 3444</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Get In Touch With Us</h2>
            <div class="bar"></div>
            <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
        </div>

        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <img src="assets/church-img/church-img-2.jpg" alt="image">
            </div>

            <div class="col-lg-6 col-md-12">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="text" class="form-control" required placeholder="Name">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input type="email" class="form-control" required placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Phone">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Subject">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="btn btn-primary">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->